//Reducer Types

//Generic
export const SET_ADMIN_AUTHENTICATED = 'SET_ADMIN_AUTHENTICATED';
export const SET_ADMIN_UNAUTHENTICATED = 'SET_ADMIN_UNAUTHENTICATED';
export const AXIOS_REQUEST = 'AXIOS_REQUEST';
export const AXIOS_REQUEST_SUCCESS = 'AXIOS_REQUEST_SUCCESS';
export const AXIOS_REQUEST_FAILURE = 'AXIOS_REQUEST_FAILURE';
export const SET_ADMIN_DASHBOARD_TAB = 'SET_ADMIN_DASHBOARD_TAB';
export const SET_SYSTEM_ACTION_TAB = 'SET_SYSTEM_ACTION_TAB';
export const SET_TABLE_INDEX = 'SET_TABLE_INDEX';
export const SET_TABLE_OBJECT_INDEX = 'SET_TABLE_OBJECT_INDEX';
export const SET_IS_VIEW = 'SET_IS_VIEW';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_TOTAL_SEARCH_COUNT = 'SET_TOTAL_SEARCH_COUNT';
export const SET_ROLES = 'SET_ROLES';

//Vehicle-Offence
export const SET_VEHICLE_OFFENSE = 'SET_VEHICLE_OFFENSE';
export const SET_VEHICLE_OFFENCE_BY_SEARCH = 'SET_VEHICLE_OFFENCE_BY_SEARCH';
export const SET_VEHICLE_OFFENCE_BY_ID = 'SET_VEHICLE_OFFENCE_BY_ID';
export const SET_VEHICLE_OFFENCE_THUMBNAILS = 'SET_VEHICLE_OFFENCE_THUMBNAILS'
export const SET_VEHICLE_OFFENSE_PAYMENT = 'SET_VEHICLE_OFFENSE_PAYMENT';
export const SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH = 'SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH'
export const SET_VEHICLE_OFFENCE_PAYMENT_BY_ID = 'SET_VEHICLE_OFFENCE_PAYMENT_BY_ID';
export const SET_VEHICLE_OFFENSE_PAYMENT_STATUSES = 'SET_VEHICLE_OFFENSE_PAYMENT_STATUSES';


//Vehicle
export const SET_VEHICLE_BY_SEARCH = 'SET_VEHICLE_BY_SEARCH';
export const SET_VEHICLE_BY_ID = 'SET_VEHICLE_BY_ID';
export const SET_ALL_VEHICLES = 'SET_ALL_VEHICLES';
export const SET_ALL_VEHICLE_OFFENSES = 'SET_ALL_VEHICLE_OFFENSES';
export const SET_VEHICLE_BROADCASTS = 'SET_VEHICLE_BROADCASTS';
export const SET_VEHICLE_CRASHES = 'SET_VEHICLE_CRASHES';
export const SET_VEHICLE_FLAGS = 'SET_VEHICLE_FLAGS';
export const SET_VEHICLE_DETAIL_CONFLICT = 'SET_VEHICLE_DETAIL_CONFLICT';
export const SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH = 'SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH';
export const SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS = 'SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS';
export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS';
export const SET_CAR_BRANDS = 'SET_CAR_BRANDS';
export const SET_VEHICLE_CONTACT = 'SET_VEHICLE_CONTACT';
export const SET_VBC_SEARCH = 'SET_VBC_SEARCH';

//Users
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_ADMIN_ROLES = 'SET_ADMIN_ROLES';
export const SET_USER_ROLES = 'SET_USER_ROLES';

//Devices
export const SET_ALL_DEVICES = 'SET_ALL_DEVICES';
export const SET_DEVICES_BY_SEARCH = 'SET_DEVICES_BY_SEARCH';
export const SET_ALL_DEVICE_USERS = 'SET_ALL_DEVICE_USERS';

//Instance-Log
export const SET_ALL_INSTANCES = 'SET_ALL_INSTANCES';
export const SET_INSTANCE_BY_SEARCH = 'SET_INSTANCE_BY_SEARCH';
export const SET_INSTANCE_BY_ID = 'SET_INSTANCE_BY_ID';
export const SET_INSTANCE_THUMBNAILS = 'SET_INSTANCE_THUMBNAILS';
export const CLEAR_INSTANCE_BY_SEARCH = 'CLEAR_INSTANCE_BY_SEARCH';
export const SET_INSTANCE_BY_SEARCH_COUNT = 'SET_INSTANCE_BY_SEARCH_COUNT';
export const SET_ALL_INSTANCES_COUNT = 'SET_ALL_INSTANCES_COUNT';

//Offences
export const SET_PAID_OFFENCES = 'SET_PAID_OFFENCES';
export const SET_UNPAID_OFFENCES = 'SET_UNPAID_OFFENCES';
export const SET_TOTAL_PAID_OFFENCES = 'SET_TOTAL_PAID_OFFENCES';
export const SET_TOTAL_UNPAID_OFFENCES = 'SET_TOTAL_UNPAID_OFFENCES';
export const SET_OFFENCE_LIST = 'SET_OFFENCE_LIST';
export const SET_OFFENCES_BY_SEARCH = 'SET_OFFENCES_BY_SEARCH';
export const SET_OFFENCE_CATEGORIES = 'SET_OFFENCE_CATEGORIES';

//Organizations
export const SET_ALL_ORGANIZATIONS = 'SET_ALL_ORGANIZATIONS';

//Persons
export const SET_ALL_PERSONS = 'SET_ALL_PERSONS';
export const SET_PERSON_BY_SEARCH = 'SET_PERSON_BY_SEARCH';
export const SET_PERSON_OFFENCES = 'SET_PERSON_OFFENCES';
export const SET_PERSON_OFFENCES_BY_SEARCH = 'SET_PERSON_OFFENCES_BY_SEARCH';
export const SET_PERSON_BROADCASTS = 'SET_PERSON_BROADCASTS';
export const SET_PERSON_OFFENCE_PAYMENT = 'SET_PERSON_OFFENCE_PAYMENT';
export const SET_PERSON_OFFENCE_PAYMENT_BY_ID = 'SET_PERSON_OFFENCE_PAYMENT_BY_ID';
export const SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH = 'SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH';
export const SET_PERSON_CONTACT = 'SET_PERSON_CONTACT';

//Location
export const SET_STATES = 'SET_STATES';
export const SET_CITIES = 'SET_CITIES';


//Liceces
export const SET_DRIVER_LICENCES = 'SET_DRIVER_LICENCES';
export const SET_DRIVER_LICENCES_BY_SEARCH = 'SET_DRIVER_LICENCES_BY_SEARCH';
export const CLEAR_DRIVER_LICENCES_BY_SEARCH = 'CLEAR_DRIVER_LICENCES_BY_SEARCH';

//Status
export const SET_STATUS = 'SET_STATUS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

//Http
export const SET_HTTP_STATUS = 'SET_HTTP_STATUS';
export const SET_HTTP_ACTION = 'SET_HTTP_ACTION';
export const SET_HTTP_METHOD = 'SET_HTTP_METHOD';
export const CLEAR_HTTP = 'CLEAR_HTTP';

//Cameras
export const SET_ALL_CAMERAS = 'SET_ALL_CAMERAS';

//Contests
export const SET_ALL_CONTESTS = 'SET_ALL_CONTESTS';
export const SET_CONTESTS_BY_SEARCH = 'SET_CONTESTS_BY_SEARCH';
export const SET_CONTEST_BY_REG_NUMBER = 'SET_CONTEST_BY_REG_NUMBER'; 
export const SET_CONTEST_STATUSES = 'SET_CONTEST_STATUSES'; 

//E-Commerce
export const SET_SERVICE_GROUPS = 'SET_SERVICE_GROUPS';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_VENDOR_SERVICES = 'SET_VENDOR_SERVICES';
export const SET_VENDOR_SERVICES_BY_ID = 'SET_VENDOR_SERVICES_BY_ID';
export const SET_ALL_USER_ROLE = 'SET_ALL_USER_ROLE';
export const SET_ESERVICE_GROUPTAX_BY_ID = 'SET_ESERVICE_GROUPTAX_BY_ID';
export const SET_ESERVICE_ACCOUNTS = 'SET_ESERVICE_ACCOUNTS';
export const SET_ESERVICE_ACCOUNT_TYPES = 'SET_ESERVICE_ACCOUNT_TYPES';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_BY_SEARCH_PARAMS = 'SET_TRANSACTIONS_BY_SEARCH_PARAMS';
export const SET_TRANSACTION_REQUESTS = 'SET_TRANSACTION_REQUESTS';
export const SET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS = 'SET_TRANSACTION_REQUEST_BY_SEARCH_PARAMS';
export const CLEAR_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS = 'CLEAR_TRANSACTION_REQUEST_BY_SEARCH_PARAMS';
export const CLEAR_TRANSACTION_BY_SEARCH_PARAMS = 'CLEAR_TRANSACTION_BY_SEARCH_PARAMS';

//Role-Action
export const SET_ALL_ADMIN_ACTION_GROUPS = 'SET_ALL_ADMIN_ACTION_GROUPS'
export const SET_SINGLE_ADMIN_ACTION_GROUP = 'SET_SINGLE_ADMIN_ACTION_GROUP'
export const SET_ALL_ADMIN_ACTIONS = 'SET_ALL_ADMIN_ACTIONS'
export const SET_SINGLE_ADMIN_ACTION = 'SET_SINGLE_ADMIN_ACTION'
export const SET_ALL_ADMIN_ROLE_ACTIONS = 'SET_ALL_ADMIN_ROLE_ACTIONS'
export const SET_ADMIN_ROLE_ACTIONS_BY_SEARCH = 'SET_ADMIN_ROLE_ACTIONS_BY_SEARCH';

//Teams
export const SET_ALL_TEAMS = 'SET_ALL_TEAMS';
export const SET_TEAM_DEVICES = 'SET_TEAM_DEVICES';

//SOT
export const SET_SOT_INSURANCE_RESPONSE = 'SET_SOT_INSURANCE_RESPONSE';
export const SET_SOT_PAAR_RESPONSE = 'SET_SOT_PAAR_RESPONSE';

//Finance
export const SET_ALL_CASH_PAYMENTS = 'SET_ALL_CASH_PAYMENTS';
export const SET_CASH_PAYMENTS_BY_SEARCH = 'SET_CASH_PAYMENTS_BY_SEARCH';
export const SET_REVENUE_GROUPS = 'SET_REVENUE_GROUPS';
export const SET_INVOICE_RECONCILIATIONS = 'SET_INVOICE_RECONCILIATIONS';
export const SET_INVOICE_RECONCILIATIONS_BY_SEARCH = 'SET_INVOICE_RECONCILIATIONS_BY_SEARCH';
export const SET_ALL_BANKS = 'SET_ALL_BANKS';
export const SET_BANKS_BY_SEARCH = 'SET_BANKS_BY_SEARCH';
export const SET_ALL_BANK_DEPOSIT_BATCHES = 'SET_ALL_BANK_DEPOSIT_BATCHES';
export const SET_BANK_DEPOSIT_BATCHES_BY_SEARCH = 'SET_BANK_DEPOSIT_BATCHES_BY_SEARCH';
export const SET_ALL_BANK_CASH_DEPOSITS = 'SET_ALL_BANK_CASH_DEPOSITS';
export const SET_BANK_CASH_DEPOSITS_BY_SEARCH = 'SET_BANK_CASH_DEPOSITS_BY_SEARCH';
export const SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS = 'SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS';
export const SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH= 'SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH';
export const SET_VEHICLE_PROFILES = 'SET_VEHICLE_PROFILES';
export const SET_VEHICLE_PROFILES_BY_SEARCH = 'SET_VEHICLE_PROFILES_BY_SEARCH';
export const SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES = 'SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES'
export const SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH = 'SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH';

//Support
export const SET_WEBSITE_MESSAGES = 'SET_WEBSITE_MESSAGES';
export const GET_CITATION_INFORMATION = 'GET_CITATION_INFORMATION';
export const SET_RESPONSE_EMAILS = 'SET_RESPONSE_EMAILS';
export const SET_INSURANCE_INFO = 'SET_INSURANCE_INFO';
export const SET_WAIVER_REQUESTS = 'SET_WAIVER_REQUESTS';
export const SET_WAIVER_REQUESTS_BY_SEARCH = 'SET_WAIVER_REQUESTS_BY_SEARCH';
export const SET_WAIVER_REQUESTS_BY_ID = 'SET_WAIVER_REQUESTS_BY_ID';
export const SET_WAIVER_REQUEST_STATUSES = 'SET_WAIVER_REQUEST_STATUSES';
export const SET_WAIVER_DOCUMENTS = 'SET_WAIVER_DOCUMENTS';
export const SET_WAIVER_COMMENTS = 'SET_WAIVER_COMMENTS';
export const SET_WAIVER_REQUEST_DATA = 'SET_WAIVER_REQUEST_DATA';

//Impounds
export const SET_ALL_YARDS = 'SET_ALL_YARDS';
export const SET_YARDS_BY_SEARCH = 'SET_YARDS_BY_SEARCH';
export const SET_ALL_YARD_LOTS = 'SET_ALL_YARD_LOTS';
export const SET_YARD_LOTS_BY_SEARCH = 'SET_YARD_LOTS_BY_SEARCH';
export const SET_YARD_LOT_BY_ID = 'SET_YARD_LOT_BY_ID';
export const SET_YARD_LOT_BY_YARD_ID = 'SET_YARD_LOT_BY_YARD_ID';
export const SET_ALL_IMPOUNDS = 'SET_ALL_IMPOUNDS';
export const SET_IMPOUNDS_BY_SEARCH = 'SET_IMPOUNDS_BY_SEARCH';
export const SET_IMPOUND_REQUEST_BY_ID = 'SET_IMPOUND_REQUEST_BY_ID'; 
export const SET_IMPOUND_REQUESTS = 'SET_IMPOUND_REQUESTS'; 
export const SET_IMPOUND_STATUS = 'SET_IMPOUND_STATUS'; 
export const SET_IMPOUND_STATUS_BY_ID = 'SET_IMPOUND_STATUS_BY_ID'; 
export const SET_ALL_IMPOUND_RR = 'SET_ALL_IMPOUND_RR';
export const SET_IMPOUND_RR_BY_SEARCH = 'SET_IMPOUND_RR_BY_SEARCH';