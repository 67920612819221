import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_ORGANIZATIONS } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getOrganizations = () => 
	getRequest({
		url: `${etraffica_baseurl}/organisation`, actionEnum: ActionEnums.GET_ORGANIZATIONS,
		disPatch: [{type: SET_ALL_ORGANIZATIONS, payload: '' }]
	});

export const addOrganization = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/organisation/update` : `${etraffica_baseurl}/organisation`,
		actionEnum: update ? ActionEnums.UPDATE_ORGANIZATIONS : ActionEnums.ADD_ORGANIZATIONS, data, update
	});