import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_DRIVER_LICENCES, SET_DRIVER_LICENCES_BY_SEARCH } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getDriverLicences = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/driverlicence`,
		actionEnum: ActionEnums.GET_DRIVERS_LICENSE, pageNumber, pageSize,
		disPatch: [{type: SET_DRIVER_LICENCES, payload: 'licences' }]
	});

export const getDriverLicencesBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/driverlicence`,
		actionEnum: ActionEnums.GET_DRIVER_LICENCES_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_DRIVER_LICENCES_BY_SEARCH, payload: 'licences' }]
	});

export const clearDriversLicencesBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_DRIVER_LICENCES_BY_SEARCH, payload: [] } )
};

export const createDriverLicence = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/driverlicence/update` : `${etraffica_baseurl}/driverlicence`,
		actionEnum: update ? ActionEnums.UPDATE_DRIVERS_LICENSE : ActionEnums.CREATE_DRIVERS_LICENSE, data, update
	});