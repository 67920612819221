export enum AdminDashboardTabs {
  Overview = 1,
  Finance = 2,
  Management = 3,
  Organization = 4,
  InstanceLogger = 5,
  Vehicles = 6,
  Offences = 7,
  Persons = 8,
  Licences = 9,
  Location = 10,
  Status = 11,
  Camera = 12,
  Ecommerce = 13,
  Contests = 14,
  Roles = 15,
  Support = 16,
  Impounds = 17
}

export enum AdminDashboardSubMenuTabs{

  CashTransactions = 21,
  InvoiceReconciliations = 22,
  Banks = 23,
  BankDepositBatch = 24,
  BankCashDeposit = 25,
  VehicleOffencePaymentWOInvoice = 26,

  UserManagement = 31,
  DeviceManagement = 32,
  DeviceUser = 33,
  TeamManagemennt = 34,
  DeviceTeam = 35,

  ViewVehicles = 61,
  VehicleOffences = 62,
  VehicleOffencePayment = 63,
  VehicleFlag = 64,
  VehicleModel = 65,
  VehicleCarBrands = 66,
  VehicleInsurance = 67,
  vehicleBroadCasts = 68,
  VehicleCrashes = 69,
  VehicleDetailConflict = 611,
  VehicleProfiles = 612,

  ViewOffences = 71,
  OffenceCategory = 72,

  ViewPersons = 81,
  PersonOffence = 82,
  PersonOffencePayments= 83,
  PersonBroadCasts = 84,

  ViewLicences = 91,

  EcommerceServiceGroups = 104,
  EcommerceVendors = 105,
  EcommerceVendorService = 106,
  EcommerceAccounts = 107,
  EcommerceTransactions = 108,
  EcommerceTransactionRequests = 109,

  AdminActions = 110,
  AdminActionGroups = 111,
  AdminRoleActions = 112,
  AdminRoles = 113,

  OutstandingPayment = 114,
  WebsiteMessages = 115,
  WaiverRequests = 116,

  ImpoundRequests = 117,
  Impounds = 118,
  ImpoundReleaseRequests = 119,
  ImpoundStatus = 120,
  Yards = 121,
  YardLots = 122,
}