import React, { FC, useState } from 'react';
import { menuData, subMenuData } from './menudata'
import { connect } from 'react-redux'
import toggleicon from 'extras/images/menu-icons/toggle.svg'
import { IMenuData } from 'interfaces/IMenu'
import { logOutAdmin } from 'store/actions';
import { NavComponent, NavIsParentComponent, NavSubComponent } from 'utils/nav'
import { IAppState } from 'interfaces/IAppState';
import { IAdmin } from 'interfaces/IAdmin';
import checkRole from 'utils/checkRole';
import './sideMenu.scss';

interface IStateProps {
    admin: IAdmin;
};

interface IDispatchProps {
    logOutAdmin?: () => void;
}

interface NewSideMenuProps extends IDispatchProps, IStateProps {
    isMenuOpen : boolean;
    toggleSidebar: ()=> void;
}

const NewSideMenu: FC<NewSideMenuProps> = ( props ) => {

    const { isMenuOpen, toggleSidebar, logOutAdmin, admin } = props

    return(
        <div className={ isMenuOpen ? "sidebar open-menu" : "sidebar" }>
            <div className="header" >
                <div className="close-btn" onClick={ toggleSidebar }>
                    <i className="far fa-times-circle" />
                </div>
            </div>
            <div>
            { menuData.map(( item, index ) => (
                    checkRole(item.view!, admin.adminRoles) &&
                    <SideMenuComponent item={ item } isLast={ ( menuData.length - 1 ) === index }
                        toggleSideBar={toggleSidebar} key={ item.id } admin={admin} />
            ) ) }
            </div>
            <div className={`menu-item-container`} style={{ marginBottom : window.innerWidth > 756? '10px': '' }}  >                
                <div className="menu-item-group" onClick={() => logOutAdmin!()}>
                    <i className="fas fa-sign-out-alt menu-icon"></i>
                    <p className={`menu-item`}>Logout</p>
                </div>
            </div>
        </div>
    )
}

interface ISMComponent extends IDispatchProps{
    item : IMenuData;
    isLast : boolean;
    toggleSideBar: () => void;
    admin: IAdmin;
}

const SideMenuComponent:FC<ISMComponent> = ( props ) => {

    const { item, isLast, admin, toggleSideBar } = props
    
    const [ isClicked, setIsClicked ] = useState( false )

    return(
        <div key={item.id} className="menu-container" > 
            {!item.isChildren ? 
            <div onClick={toggleSideBar}>
                <NavComponent item={ item } >
                    <div className="menu-item-group">
                        <img src={ item.menuicon } alt="menu icon" className="menu-icon"/>
                        <p className={`menu-item menu-item-active`} >{item.title}</p>
                    </div>
                </NavComponent>
            </div>
            :
            <NavIsParentComponent item={ item }  setIsClicked={  setIsClicked } isClicked={ isClicked } >
                <div className="menu-item-group">
                    <img src={ item.menuicon } alt="menu icon" className="menu-icon"/>
                    <p className={`menu-item menu-item-active`} >{item.title}</p>
                </div>
                { item.isChildren && <img src={ toggleicon } alt="toggle icon" 
                    className={`menu-toggle ${isClicked && "menu-toggle-open"}`}/> }
            </NavIsParentComponent>}

            <div className={`menu-subitem-container ${ isClicked && "open" }`} onClick={toggleSideBar}> 
                {item.isChildren && item.children && 
                item.children( subMenuData )
                .filter(( sub ) => sub.parentid === item.id )
                .map( ( subitem ) => (
                    checkRole(subitem.view!, admin.adminRoles) &&
                    <NavSubComponent subItem={ subitem } isLast={ isLast } key={ subitem.id } >
                        <p className="menu-subitem-text" >
                            { subitem.title }
                        </p>
                    </NavSubComponent>
                ))} 

            </div>    
            
        </div>
    )
}

const mapStateToProps = (state: IAppState): IStateProps => ({
    admin: state.admin,
});

const mapDispatchToProps: IDispatchProps = {
    logOutAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSideMenu);