import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_VEHICLES, SET_CAR_BRANDS, SET_VBC_SEARCH, SET_VEHICLE_BROADCASTS, SET_VEHICLE_BY_ID, SET_VEHICLE_BY_SEARCH, SET_VEHICLE_CONTACT, SET_VEHICLE_CRASHES, SET_VEHICLE_DETAIL_CONFLICT, SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH, SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS, SET_VEHICLE_FLAGS, SET_VEHICLE_MODELS, SET_VEHICLE_PROFILES, SET_VEHICLE_PROFILES_BY_SEARCH } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllVehicles = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicle`, actionEnum: ActionEnums.GET_ALL_VEHICLES,
		disPatch: [{type: SET_ALL_VEHICLES, payload: 'vehicles' }], pageNumber, pageSize,
	});

export const getVehicleBySearch = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicle`,
		actionEnum: ActionEnums.GET_VEHICLE_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_VEHICLE_BY_SEARCH, payload: 'data' }]
	});

export const getVehicleById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicle`, actionEnum: ActionEnums.GET_VEHICLE_BY_ID, id,
		disPatch: [{type: SET_VEHICLE_BY_ID, payload: '' }]
	});

export const clearVehicleBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_VEHICLE_BY_SEARCH, payload: [] } )
};

export const clearVehicleById = () => ( dispatch : Function ) => {
	dispatch( { type : SET_VEHICLE_BY_ID, payload: {} } )
};

export const addNewVehicle = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/vehicle`, actionEnum: ActionEnums.ADD_VEHICLE, data
	});

export const getVehicleModels = () => 
	getRequest({
		url: `${etraffica_baseurl}/vehiclemodel`, actionEnum: ActionEnums.GET_VEHICLE_MODELS,
		disPatch: [{type: SET_VEHICLE_MODELS, payload: 'vehicleModels' }]
	});

export const addVehicleModel = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/vehiclemodel/update` : `${etraffica_baseurl}/vehiclemodel`,
		actionEnum: update ? ActionEnums.UPDATE_VEHICLE_MODEL : ActionEnums.ADD_VEHICLE_MODEL, data, update
	});

export const getVehicleContact = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/vehicleoffensecontact/violation`, actionEnum: ActionEnums.GET_VEHICLE_CONTACT, data,
		disPatch:[{type: SET_VEHICLE_CONTACT, payload: ''}]
	});

export const updateVehiclePlateNo = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/Vehicle/update/regnumber`, actionEnum: ActionEnums.UPDATE_VEHICLE_PLATE_NO, data,
	});

export const getCarBrands = () => 
	getRequest({
		url: `${etraffica_baseurl}/carbrand`, actionEnum: ActionEnums.GET_CAR_BRANDS,
		disPatch: [{type: SET_CAR_BRANDS, payload: 'carBrands' }]
	});

export const createCarBrand = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/carbrand/update` : `${etraffica_baseurl}/carbrand`,
		actionEnum: update ? ActionEnums.UPDATE_CAR_BRAND : ActionEnums.CREATE_CAR_BRAND, data, update
	});

export const getVehicleFlags = () => 
	getRequest({
		url: `${etraffica_baseurl}/Vehicleflag`, actionEnum: ActionEnums.GET_VEHICLE_FLAG,
		disPatch: [{type: SET_VEHICLE_FLAGS, payload: '' }]
	});

export const createVehicleFlag = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/Vehicleflag/update` : `${etraffica_baseurl}/Vehicleflag`,
		actionEnum: update ? ActionEnums.UPDATE_VEHICLE_FLAG : ActionEnums.CREATE_VEHICLE_FLAG , data, update
	});

export const createVehicleBroadcast = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/broadcast/vehicle/update` : `${etraffica_baseurl}/broadcast/vehicle`,
		actionEnum: update ? ActionEnums.UPDATE_VEHICLE_BROADCAST : ActionEnums.CREATE_VEHICLE_BROADCAST, data, update
	});

export const getVehicleBroadCasts = () => 
	getRequest({
		url: `${etraffica_baseurl}/broadcast/vehicle`, actionEnum: ActionEnums.GET_VEHICLE_BORADCAST,
		disPatch: [{type: SET_VEHICLE_BROADCASTS, payload: 'vehicleBroadcast' }]
	});

export const getVBCSearch = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/broadcast/vehicle/search`, actionEnum: ActionEnums.GET_VEHICLE_BORADCAST_BY_SEARCH, data,
		disPatch:[{type: SET_VBC_SEARCH, payload: ''}]
	});

export const createVehicleCrash = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/vehiclecrash/update` : `${etraffica_baseurl}/broadcast/vehicle`,
		actionEnum: update ? ActionEnums.UPDATE_VEHICLE_CRASH : ActionEnums.CREATE_VEHICLE_CRASH, data, update
	});

export const getVehicleCrashes = () => 
	getRequest({
		url: `${etraffica_baseurl}/vehiclecrash`, actionEnum: ActionEnums.GET_ALL_VEHICLE_CRASH,
		disPatch: [{type: SET_VEHICLE_CRASHES, payload: '' }]
	});

export const getVehicleDetailConflict = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicledetailconflict`, actionEnum: ActionEnums.GET_VEHICLE_DETAIL_CONFLICTS,
		disPatch: [{type: SET_VEHICLE_DETAIL_CONFLICT, payload: 'data' }], pageNumber, pageSize,
	});

export const getVehicleDetailConflictBySearch = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicledetailconflict`,
		actionEnum: ActionEnums.GET_VEHICLE_DETAIL_CONFLICTS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH, payload: 'data' }]
	});

export const clearVehicleDetailConflictBySearch = () => (dispatch: Function) => {
	dispatch({ type : SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH, payload : [] });
};

export const getVehicleProfiles = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleprofiler`, actionEnum: ActionEnums.GET_VEHICLE_PROFILES,
		disPatch: [{type: SET_VEHICLE_PROFILES, payload: 'data' }], pageNumber, pageSize,
	});

export const getVehicleProfilesBySearch = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleprofiler`,
		actionEnum: ActionEnums.GET_VEHICLE_PROFILES_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_VEHICLE_PROFILES_BY_SEARCH, payload: 'data' }]
	});

export const clearVehicleProfilesBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_VEHICLE_PROFILES_BY_SEARCH, payload: [] })
};

export const getResolutionStatus = () => 
	getRequest({
		url: `${etraffica_baseurl}/vehicledetailconflict/resolutions`, 
		actionEnum: ActionEnums.GET_VEHICLE_DETAIL_CONFLICTS_RESOLUTIONS,
		disPatch: [{type: SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS, payload: 'data' }]
	});

export const updateResolutionStatus = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/vehicledetailconflict/update/status`, actionEnum: ActionEnums.UPDATE_RESOLUTION_STATUS, data,
	});