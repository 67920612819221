export const pageurl = {
    TMLOGIN: '/',
    TMADMIN: '/admin-dashboard',
};

export const etraffica_baseurl = process.env.REACT_APP_ETRAFFIKA_BASEURL;

// 'https://dev-etraffica.azurewebsites.net/api';

export const validEmailRegex = /\S+@\S+\.\S+/;

export const naijaPhoneRegex = /^[+][0-9]\d{9,13}$/;
// /^[+][1-9][0-9]{9,13}$/