import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_CAMERAS, SET_STATUS } from '../types';
import { getRequest, postRequest } from 'store/services';

export const createStatus = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/status`, actionEnum: ActionEnums.CREATE_STATUS, data
	});

export const getStatus = () => 
	getRequest({
		url: `${etraffica_baseurl}/status`, actionEnum: ActionEnums.GET_STATUS,
		disPatch: [{type: SET_STATUS, payload: '' }]
	});

export const addNewCamera = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/camera`, actionEnum: ActionEnums.ADD_NEW_CAMERA, data
	});

export const getCameras = () => 
	getRequest({
		url: `${etraffica_baseurl}/camera`, actionEnum: ActionEnums.GET_ALL_CAMERAS,
		disPatch: [{type: SET_ALL_CAMERAS, payload: 'cameras' }]
	});