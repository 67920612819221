
export enum ActionEnums {
    //user
    LOGIN = 'Login',
    GET_ALL_USERS = 'Get all users',
    ADD_NEW_USER = 'Add new user',
    DISABLE_USER = 'Disable user',
    UPDATE_USER = 'Update user',
    UPDATE_USER_ROLE = 'Update user role',
    SET_ADMIN_ROLES = 'Set Admin Roles',
    CHANGE_PASSWORD = 'Change Password',
    REDIRECT = 'Redirected to login',

    //devices
    GET_ALL_DEVICE_USERS = 'Get all device users',
    GET_ALL_DEVICES = 'Get all devices',
    GET_DEVICES_BY_SEARCH = 'Get devices by search',
    ADD_DEVICE = 'Add device',
    ASSIGN_DEVICE = 'Assign device',
    UPDATE_DEVICE = 'Update device',

    //offence
    CREATE_OFFENCE = 'Create offence',
    UPDATE_OFFENCE = 'Update offence',
    GET_OFFENCE_LIST = 'Get offence list',
    GET_OFFENCES_BY_SEARCH = 'Get offences by search',
    CREATE_OFFENCE_CATEGORY = 'Create offence category',
    UPDATE_OFFENCE_CATEGORY = 'Update offence category',
    GET_OFFENCE_CATEGORY = 'Get offence category',

    //state
    CREATE_COUNTRY = 'Create country',
    CREATE_STATE = 'Create state',
    UPDATE_STATE = 'Update state',
    CREATE_CITY = 'Create city',
    UPDATE_CITY = 'Update city',
    GET_STATES = 'Get states',
    GET_CITIES = 'Get cities',

    //organizations
    GET_ORGANIZATIONS = 'Get organizations',
    ADD_ORGANIZATIONS = 'Add organizations',
    UPDATE_ORGANIZATIONS = 'Update organizations',

    //persons
    ADD_PERSON = 'Add person',
    UPDATE_PERSON = 'Update person',
    GET_ALL_PERSONS = 'Get all persons',
    GET_PERSON_BY_SEARCH = 'Get Person by Search',
    GET_PERSON_OFFENCES = 'Get person offences',
    GET_PERSON_CONTACT = 'Get person contact',
    GET_PERSON_OFFENCE_BY_SEARCH = 'Get person offence by search',
    GET_PERSON_OFFENCE_PAYMENT = 'Get person offece payment',
    GET_PERSON_OFFENCE_PAYMENT_BY_ID = 'Get person offece payment by perso offence Id',
    GET_PERSON_OFFENCE_PAYMENT_BY_SEARCH = 'Get person offece payment by search ',

    //person broadcast
    GET_PERSON_BROADCAST = 'Get person broadcast',
    CREATE_PERSON_BROADCAST = 'Create person broadcast',
    UPDATE_PERSON_BROADCAST = 'Update person broadcast',

    //vehicle
    ADD_VEHICLE_MODEL = 'Add vehicle model',
    UPDATE_VEHICLE_MODEL = 'Update vehicle model',
    GET_VEHICLE_MODELS = 'Get vehicle model',
    ADD_VEHICLE = 'Add vehicle',
    GET_ALL_VEHICLES = 'Get all vehicles',
    GET_VEHICLE_CONTACT = 'Get vehicle contact',
    GET_VEHICLE_BY_SEARCH = 'Get vehicle by search',
    GET_VEHICLE_BY_ID = 'Get vehicle by ID',
    UPDATE_VEHICLE_PLATE_NO = 'Update vehicle plate number',
    GET_VEHICLE_DETAIL_CONFLICTS = 'Get vehicle detail conflicts',
    GET_VEHICLE_DETAIL_CONFLICTS_BY_SEARCH = 'Get vehicle detail conflicts by search',
    GET_VEHICLE_DETAIL_CONFLICTS_RESOLUTIONS = 'Get vehicle detail conflicts resolutions',
    UPDATE_RESOLUTION_STATUS = 'Update resolution status',
    GET_VEHICLE_PROFILES = 'Get vehicle profiles',
    GET_VEHICLE_PROFILES_BY_SEARCH = 'Get vehicle profiles by search',

    //vehicle offences
    GET_ALL_VEHICLE_OFFENCES = 'Get all vehicle offences',
    GET_VEHICLE_OFFENCE_BY_SEARCH = 'Get vehicle offence by search',
    GET_VEHICLE_OFFENCE_BY_ID = 'Get vehicle offence by id',
    GET_ALL_VEHICLE_OFFENCE_PAYMENT = 'Get all vehicle offence payment',
    GET_ALL_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH = 'Get all vehicle offence payment by search',
    GET_ALL_VEHICLE_OFFENCE_PAYMENT_BY_ID = 'Get all vehicle offence payment by ID',
    ADD_VEHICLE_OFFENCE = 'Add vehicle offence',
    GET_INVOICE_ID = 'Get Invoice Id',
    GET_ALL_VEHICLE_OFFENCE_PAYMENT_STATUSES = 'Get all vehicle offence payment statuses',

    //vehicle flag
    CREATE_VEHICLE_FLAG = 'Create vehicle flag',
    UPDATE_VEHICLE_FLAG = 'Update vehicle flag',
    GET_VEHICLE_FLAG = 'Get vehicle flag',

    //vehicle broadcast
    CREATE_VEHICLE_BROADCAST = 'Create vehicle broadcast',
    UPDATE_VEHICLE_BROADCAST = 'Update vehicle broadcast',
    GET_VEHICLE_BORADCAST = 'Get vehicle broadcast',
    GET_VEHICLE_BORADCAST_BY_SEARCH = 'Get vehicle broadcast by search',

    //vehicle crash
    CREATE_VEHICLE_CRASH = 'Create vehicle crash',
    UPDATE_VEHICLE_CRASH = 'Update vehicle crash',
    GET_ALL_VEHICLE_CRASH = 'Get all vehicle crash',

    //car brand
    CREATE_CAR_BRAND = 'Create car brand',
    UPDATE_CAR_BRAND = 'Update car brand',
    GET_CAR_BRANDS = 'Get car brands',

    //drivers license
    GET_DRIVERS_LICENSE = 'Get drivers license',
    GET_DRIVER_LICENCES_BY_SEARCH = 'Get drivers licences by search',
    CREATE_DRIVERS_LICENSE = 'Create drivers licence',
    UPDATE_DRIVERS_LICENSE = 'Update drivers licence',
    
    //status
    CREATE_STATUS = 'Create status',
    UPDATE_STATUS = 'Update status',
    GET_STATUS = 'Get all status',

    //camera
    ADD_NEW_CAMERA = 'Add new camera',
    UPDATE_NEW_CAMERA = 'Update camera',
    GET_ALL_CAMERAS = 'Get all cameras',

    //contests
    GET_ALL_CONTESTS = 'Get all contests',
    GET_CONTESTS_BY_SEARCH= 'Get contests by search',
    UPDATE_CONTEST = 'Update Contest',
    GET_CONTEST_BY_REG_NUMBER = 'Get Contest by regNumber',
    GET_CONTEST_STATUSES = 'Get contest statuses',

    //notification
    SEND_NOTIFICATION = 'Send notification',
    BOOK_VEHICLE = 'Book vehicle',
    ISSUE_WARNING = 'Issue warning',
    SOT_INSURANCE_CHECK = 'Insurance Regulatory check',
    SOT_PAAR_CHECK = 'General Regulatory check',
    
    //instances
    GET_ALL_INSTANCES = 'Get all instances',
    GET_INSTANCES_BY_SEARCH = 'Get instances by search',
    GET_INSTANCE_BY_ID = 'Get instances by ID',
    
    //violations
    GET_VIOLATION_INFO = 'Get Violation Information',
    GET_PAID_OFFENCES = 'Get paid offences',
    GET_UNPAID_OFFENCES = 'Get unpaid offences',
    GET_TOTAL_OFFENCES = 'Get total offences',

    //ecommerce
    GET_SERVICE_GROUPS = 'Get service groups',
    CREATE_SERVICE_GROUP = 'Create Service group',
    GET_VENDORS = 'Get vendors',
    CREATE_VENDOR = 'Create vendor',
    UPDATE_VENDOR = 'Update Vendor',
    GET_VENDOR_SERVICE = 'Get Vendor Service',
    GET_VENDOR_SERVICE_BY_ID = 'Get vendor service by id',
    CREATE_VENDOR_SERVICE = 'Create vendor service',
    UPDATE_VENDOR_SERVICE = 'Update vendor service',
    CREATE_TRANSACTION = 'Create transaction',
    GET_E_SERVICE_GROUP_TAX_BY_ID = 'Get e-service group tax by id',
    GET_ACCOUNTS = 'Get Accounts',
    CREATE_ACCOUNT = 'Create Account',
    UPDATE_ACCOUNT = 'Update Account',
    GET_ACCOUNT_TYPES = 'Get Account types',
    LINK_DEVICE_TO_ACCOUNT = 'Link Device to Account',
    GET_TRANSACTIONS = 'Get transactions',
    GET_TRANSACTIONS_BY_SEARCH_PARAMS = 'Get transactions by search parameters',
    GET_TRANSACTION_REQUESTS = 'Get transactions requests',
    GET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS = 'Get transaction requests by search parameters',

    //role
    GET_ALL_ACTION_GROUPS = 'Get all action groups',
    GET_SINGLE_ACTION_GROUPS = 'Get single action groups',
    ADD_NEW_ACTION_GROUP = 'Add new action group',
    GET_ALL_ACTIONS = 'Get all actions',
    GET_SINGLE_ACTION = 'Get single action',
    ADD_NEW_ACTION = 'Add new action',
    GET_ALL_ROLE_ACTIONS = 'Get all role actions',
    GET_ALL_ROLE_ACTIONS_BY_SEARCH = 'Get all role actions by search',
    GET_USER_ROLES = 'Get User Roles',
    ASSIGN_ROLE_ACTION = 'Assign role action',
    CREATE_ROLE = 'Create role',
    GET_ROLES = 'Get Roles',
    UNASSIGN_ROLE_ACTION = 'Unassign admin role action',
    GET_ALL_ROLES = 'Get all roles',

    //teams
    GET_ALL_TEAMS = 'Get all teams',
    ADD_NEW_TEAM = 'Add new team',
    UPDATE_TEAM = 'Update team',
    ASSIGN_DEVICE_TEAM = 'Assign device to team',
    GET_TEAM_DEVICES = 'Get team devices',

    //finance
    GET_ALL_CASH_PAYMENTS = 'Get Cash Payments',
    GET_CASH_PAYMENTS_BY_SEARCH = 'Get Cash Payments By Search',
    SYNC_CASH_PAYMENT = 'Synchronixe cash payment',
    GET_INVOICE_RECONCILIATIONS = 'Get invoice reconciliations',
    GET_INVOICE_RECONCILIATIONS_BY_SEARCH = 'Get invoice reconciliations by search',
    GET_ALL_BANKS = 'Get all banks',
    GET_BANKS_BY_SEARCH = 'Get banks by search',
    CREATE_BANK = 'Create Bank',
    UPDATE_BANK = 'Update Bank',
    REVERSE_CASH_PAYMENT = 'Cash Payment Reversal',

    GET_ALL_BANK_DEPOSIT_BATCHES = 'Get all bank deposit branches',
    GET_BANK_DEPOSIT_BATCHES_BY_SEARCH = 'Get bank deposit branches by search',
    CREATE_BANK_DEPOSIT_BATCH = 'Create Bank deposit branch',
    UPDATE_BANK_DEPOSIT_BATCH = 'Update Bank deposit batch',

    GET_ALL_BANK_CASH_DEPOSIT = 'Get all bank cash deposits',
    GET_BANK_CASH_DEPOSIT_BY_SEARCH = 'Get bank cash deposits by search',
    CREATE_BANK_CASH_DEPOSIT = 'Create Bank cash deposit',
    UPDATE_BANK_CASH_DEPOSIT = 'Update Bank cash deposit',
    RECONCILE_INVOICE = 'Invoice reconciliation',
    FIX_DUPLICATE_INVOICE = 'Fix duplicate invoice',

    //revenue-group
    GET_ALL_REVENUE_GROUPS = 'Get all reveue groups',

    //support
    GET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS = 'Get all outstanding vehicle offence payments',
    GET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH = 'Get all outstanding vehicle offence payments by search',
    GET_WEBSITE_MESSAGES = 'Get all contact messages from the website',
    DELETE_WEBSITE_MESSAGE = 'Delete a contact message',
    GET_CITATION_INFORMATION = 'Get citation information',
    GET_INSURANCE_INFORMATION = 'Get insurance information',
    GET_RESPONSE_EMAILS = 'Get response emails',
    RESPOND_TO_EMAIL = 'Respond to Email',
    GET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES = 'Get all vehicle offence payments without invoices',
    GET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH = 'Get all vehicle offence payments without invoices by search',
    GET_WAIVER_REQUESTS = 'Get waiver requests',
    GET_WAIVER_REQUESTS_BY_SEARCH = 'Get waiver requests by search',
    GET_WAIVER_REQUEST_BY_ID = 'Get waiver request by id',
    CREATE_WAIVER = 'Create waiver',
    DELETE_WAIVER = 'Delete waiver',
    UPDATE_WAIVER = 'Update waiver',
    GET_WAIVER_REQUEST_STATUSES = 'Get waiver request statuses',
    CREATE_WAIVER_REQUEST_STATUS = 'Create waiver request status',
    UPDATE_WAIVER_REQUEST_STATUS = 'Update waiver request status',
    UPLOAD_WAIVER_DOCS = 'Upload waiver documents',
    GET_WAIVER_DOCUMENTS = 'Get Waiver Documents',
    DELETE_WAIVER_DOCUMENTS = 'Delete Waiver Documents',
    ADD_WAIVER_REQUEST_COMMENTS = 'Add Waiver Comments',
    DELETE_WAIVER_COMMENT = 'Delete Waiver Comment',
    GET_WAIVER_COMMENTS = 'Get Waiver Comments',
    DISAPPROVE_WAIVER = 'Disapprove Waiver',
    APPROVE_WAIVER = 'Approve Waiver',

    //impounds
    GET_ALL_YARDS = 'Get all yards',
    GET_YARDS_BY_SEARCH = 'Get yards by search',
    ADD_YARD = 'Add new yard',
    UPDATE_YARD = 'Update yard',
    GET_ALL_YARD_LOTS = 'Get all yard lots',
    GET_YARD_LOTS_BY_SEARCH = 'Get yard lots by search',
    GET_YARD_LOT_BY_ID = 'Get yard lot by ID',
    GET_YARD_LOT_BY_YARD_ID = 'Get yard lot by yard ID',
    ADD_YARD_LOT = 'Add new yard lot',
    UPDATE_YARD_LOT = 'Update yard lot',
    GET_ALL_IMPOUNDS = 'Get all impounds',
    GET_IMPOUNDS_BY_SEARCH = 'Get impounds by search',
    APPROVE_IMPOUND = 'Approve Impound Request',
    REJECT_IMPOUND = 'Reject Impound Request',
    UPDATE_IMPOUND = 'Update Impound',
    DELETE_IMPOUND = 'Delete Impound',
    GET_IMPOUND_REQUEST = 'Get impound request',
    GET_IMPOUND_REQUEST_BY_ID = 'Get impound request by id',
    ADD_IMPOUND_REQUEST = 'Add impound request',
    UPDATE_IMPOUND_REQUEST = 'Update impound request',
    GET_ALL_IMPOUND_STATUS = 'Get all impound status',
    GET_IMPOUND_STATUS_BY_ID = 'Get impound status by id',
    ADD_IMPOUND_STATUS = 'Add impound status',
    UPDATE_IMPOUND_STATUS = 'Update impound status',
    GET_ALL_IMPOUND_RELEASE_REQUESTS = 'Get all impound release requests',
    GET_IMPOUND_RELEASE_REQUESTS_BY_SEARCH = 'Get impound release requests by search',
    CREATE_IMPOUND_RELEASE_REQUEST = 'Create impound release request',
    UPDATE_IMPOUND_RELEASE_REQUEST = 'Update impound release request',
    APPROVE_IMPOUND_RELEASE_REQUEST = 'Approve impound release request',
    REJECT_IMPOUND_RELEASE_REQUEST = 'Reject impound release request',

}