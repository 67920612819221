import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Notification from './components/notification';
import DashboardWrapper from './utils/dashboardwrapper'
import loader from './extras/images/loader/loader.svg'
import RouteList from './routes';
import './index.scss';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  }
});

const Routes = () => (
  <Router>

    <Notification/>
    <DashboardWrapper>

      <Suspense fallback={ <div className="loader-container"><img src={ loader } alt="loader icon" /></div> } >          
        <RouteList />
      </Suspense>
      
    </DashboardWrapper>

  </Router>
);

ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
