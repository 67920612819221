import {
	SET_ADMIN_AUTHENTICATED, SET_ADMIN_UNAUTHENTICATED, AXIOS_REQUEST,SET_ADMIN_ROLES,
	AXIOS_REQUEST_FAILURE, AXIOS_REQUEST_SUCCESS, SET_ADMIN_DASHBOARD_TAB,
	SET_ALL_USERS, SET_ALL_DEVICES, SET_ALL_ORGANIZATIONS, SET_ALL_PERSONS,
	SET_VEHICLE_MODELS, SET_TABLE_INDEX, SET_OFFENCE_LIST, SET_SYSTEM_ACTION_TAB,
	SET_CITIES, SET_STATES, SET_CAR_BRANDS, SET_OFFENCE_CATEGORIES, SET_DRIVER_LICENCES,
	SET_ALL_VEHICLES, SET_VEHICLE_FLAGS, SET_ALL_VEHICLE_OFFENSES, SET_VEHICLE_OFFENSE,
	SET_STATUS, SET_TABLE_OBJECT_INDEX, SET_PERSON_OFFENCES, SET_VEHICLE_BROADCASTS, SET_PERSON_BROADCASTS,
	SET_VEHICLE_OFFENCE_BY_SEARCH, SET_PERSON_OFFENCES_BY_SEARCH, SET_VEHICLE_BY_SEARCH, SET_ALL_CAMERAS,
	SET_VEHICLE_CONTACT, SET_PERSON_CONTACT, SET_VBC_SEARCH, SET_ALL_USER_ROLE, SET_ALL_DEVICE_USERS,
	SET_VEHICLE_CRASHES, SET_TOTAL_COUNT, SET_TOTAL_SEARCH_COUNT, SET_PERSON_OFFENCE_PAYMENT, 
	SET_PERSON_OFFENCE_PAYMENT_BY_ID, SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH, SET_VEHICLE_OFFENSE_PAYMENT, SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH,
	SET_PERSON_BY_SEARCH, SET_DRIVER_LICENCES_BY_SEARCH, CLEAR_DRIVER_LICENCES_BY_SEARCH,
	SET_VEHICLE_OFFENCE_THUMBNAILS, SET_ALL_CONTESTS, SET_VEHICLE_OFFENCE_BY_ID, SET_CONTEST_BY_REG_NUMBER, SET_VEHICLE_DETAIL_CONFLICT,
	SET_CONTESTS_BY_SEARCH, SET_ALL_TEAMS, SET_TEAM_DEVICES, SET_CASH_PAYMENTS_BY_SEARCH, SET_ALL_CASH_PAYMENTS, SET_CONTEST_STATUSES,
	SET_SOT_INSURANCE_RESPONSE, SET_SOT_PAAR_RESPONSE, SET_REVENUE_GROUPS, SET_OFFENCES_BY_SEARCH, SET_DEVICES_BY_SEARCH, 
	SET_INVOICE_RECONCILIATIONS, SET_INVOICE_RECONCILIATIONS_BY_SEARCH, SET_ALL_BANKS, SET_BANKS_BY_SEARCH, SET_ALL_BANK_DEPOSIT_BATCHES, 
	SET_BANK_DEPOSIT_BATCHES_BY_SEARCH, SET_ALL_BANK_CASH_DEPOSITS, SET_BANK_CASH_DEPOSITS_BY_SEARCH, SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH,
	SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS,
	SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS,
	SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH,
	SET_VEHICLE_PROFILES,
	SET_VEHICLE_PROFILES_BY_SEARCH,
	SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES,
	SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH,
	SET_VEHICLE_BY_ID,
	SET_WEBSITE_MESSAGES,
	SET_VEHICLE_OFFENCE_PAYMENT_BY_ID,
	GET_CITATION_INFORMATION,
	SET_INSURANCE_INFO,
	SET_WAIVER_REQUESTS,
	SET_WAIVER_REQUESTS_BY_SEARCH,
	SET_WAIVER_REQUEST_STATUSES,
	SET_WAIVER_DOCUMENTS,
	SET_WAIVER_COMMENTS,
	SET_WAIVER_REQUEST_DATA,
	SET_WAIVER_REQUESTS_BY_ID,
	SET_ALL_YARDS,
	SET_ALL_YARD_LOTS,
	SET_ALL_IMPOUNDS,
	SET_IMPOUNDS_BY_SEARCH,
	SET_IMPOUND_REQUESTS,
	SET_IMPOUND_REQUEST_BY_ID,
	SET_IMPOUND_STATUS, 
	SET_IMPOUND_STATUS_BY_ID,
	SET_ALL_IMPOUND_RR,
	SET_IMPOUND_RR_BY_SEARCH,
	SET_YARD_LOT_BY_ID,
	SET_VEHICLE_OFFENSE_PAYMENT_STATUSES,
	SET_YARD_LOT_BY_YARD_ID
} from "../actions/types";

const initialState = {
	authenticated: false,
	loading: false,
	action: '',
	errors: '',
	data: [],
	users: [],
	adminRoles: [],
	role:[],
	devices: [],
	devicesBySearch: [],
	deviceUsers: [],
	teams: [],
	teamDevices: [],
	organizations: [],
	persons: [],
	personsBySearch: [],
	personContact: {},
	personOffences: [],
	personOffencesBySearch : [],
	personOffencePayment: [],
	personOffencePaymentBySearch: [],
	personOffencePaymentById: {},
	personBroadCasts: [],
	vehicles: [],
	vehicleContact: {},
	vehiclesBySearch: [],
	vehicleById: {},
	vehicleModels: [],
	vehicleOffenses:[],
	vehicleOffencesBySearch:[],
	vehicleOffenceById:{},
	vehicleOffencesThumbnails:[],
	vehicleOffencePayment:[],
	vehicleOffencePaymentBySearch:[],
	vehicleOffencePaymentById:{},
	vehicleOffencePaymentStatuses:[],
	vehicleOffense:[],
	vehicleBroadCasts: [],
	vehicleCrashes: [],
	vehicleBroadCastsBySearch: [],
	vehicleDetailConflicts:[],
	vehicleDetailConflictsBySearch:[],
	resolutionStatus:[],
	offenceList: [],
	offencesBySearch: [],
	cities: [],
	carBrands: [],
	states: [],
	offenceCategories: [],
	driverLicences: [],
	driverLicencesBySearch: [],
	vehicleFlags: [],
	status:[],
	cameras: [],
	contests: [],
	contestsBySearch: [],
	contestByRegNumber: [],
	contestStatuses: [],
	cashPayments: [],
	cashPaymentsBySearch: [],
	sotInsurance: {},
	sotPaar: {},
	revenueGroups: [],
	iReconciliations: [],
	iReconciliationsBySearch: [],
	banks: [],
	banksBySearch: [],
	bankDepositBatches: [],
	bankDepositBatchesBySearch: [],
	bankCashDeposits: [],
	bankCashDepositsBySearch: [],
	outstandingVehicleOffencePayments: [],
	outstandingVehicleOffencePaymentsBySearch: [],
	vehicleProfiles: [],
	vehicleProfilesBySearch: [],
	vehicleOffencePaymentsWOInvoices: [],
	vehicleOffencePaymentsWOInvoicesBySearch: [],
	websiteMessages: [],
	citationInformation: {},
	insuranceInformation: {},
	waiverRequests: [],
	waiverRequestsBySearch: [],
	waiverRequestById: {},
	waiverRequestStatuses: [],
	waiverRequestDocument: [],
	waiverRequestComment:[],
	waiverRequestData:{},
	yards: [],
	yardLots: [],
	yardLotById: {},
	yardLotsByYardId: [],
	impounds: [],
	impoundsBySearch: [],
	impoundRequests:[],
	impoundRequestById: {},
	impoundStatus:[],
	impoundStatusById:{},
	impoundRR: [],
	impoundRRBySearch: [],
	tabNumber: 1,
	tableIndex: 1,
	tableObjectIndex: 1,
	systemActionsTab: 1,
	totalCount: 0,
	totalSearchCount: 0
};

const adminReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		case SET_ADMIN_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
			};
		case SET_ADMIN_UNAUTHENTICATED:
			return {
				...state,
				authenticated: false
			};
		case SET_ADMIN_DASHBOARD_TAB:
			return {
				...state,
				tabNumber: action.payload
			};
		case SET_SYSTEM_ACTION_TAB:
			return {
				...state,
				systemActionsTab: action.payload
			};
		case SET_TABLE_INDEX:
			return {
				...state,
				tableIndex: action.payload
			};
		case SET_TABLE_OBJECT_INDEX:
			return {
				...state,
				tableObjectIndex: action.payload
			};
		case SET_ALL_USERS:
			return {
				...state,
				users: action.payload
			};
		case SET_ALL_TEAMS:
			return {
				...state,
				teams: action.payload
			};
		case SET_TEAM_DEVICES:
			return {
				...state,
				teamDevices: action.payload
			};
		case SET_ADMIN_ROLES:
			return {
				...state,
				adminRoles: action.payload
			};
		case SET_ALL_DEVICES:
			return {
				...state,
				devices: action.payload
			};
		case SET_DEVICES_BY_SEARCH:
			return {
				...state,
				devicesBySearch: action.payload
			};
		case SET_ALL_DEVICE_USERS:
			return {
				...state,
				deviceUsers: action.payload
			};
		case SET_ALL_ORGANIZATIONS:
			return {
				...state,
				organizations: action.payload
			};
		case SET_ALL_PERSONS:
			return {
				...state,
				persons: action.payload
			};
		case SET_PERSON_BY_SEARCH:
			return {
				...state,
				personBySearch: action.payload
			};
		case SET_PERSON_OFFENCES:
			return {
				...state,
				personOffences: action.payload
			};
		case SET_PERSON_CONTACT:
			return {
				...state,
				personContact: action.payload
			};
		case SET_PERSON_OFFENCES_BY_SEARCH:
			return {
				...state,
				personOffencesBySearch: action.payload
			};
		case SET_PERSON_OFFENCE_PAYMENT:
			return {
				...state,
				personOffencePayment: action.payload
			};
		case SET_PERSON_OFFENCE_PAYMENT_BY_ID:
			return {
				...state,
				personOffencePaymentById: action.payload
			};
		case SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH:
			return {
				...state,
				personOffencePaymentBySearch: action.payload
			};
		case SET_PERSON_BROADCASTS:
			return {
				...state,
				personBroadCasts: action.payload
			};
		case SET_ALL_VEHICLES:
			return {
				...state,
				vehicles: action.payload
			};
		case SET_VEHICLE_CONTACT:
			return {
				...state,
				vehicleContact: action.payload
			};
		case SET_VEHICLE_BY_SEARCH:
			return {
				...state,
				vehiclesBySearch: action.payload
			};
		case SET_VEHICLE_BY_ID:
			return {
				...state,
				vehicleById: action.payload
			};
		case SET_VEHICLE_OFFENCE_BY_ID:
			return {
				...state,
				vehicleOffenceById: action.payload
			};
		case SET_VEHICLE_MODELS:
			return {
				...state,
				vehicleModels: action.payload
			};
		case SET_VEHICLE_CRASHES:
			return {
				...state,
				vehicleCrashes: action.payload
			};
		case SET_VEHICLE_OFFENSE_PAYMENT:
			return {
				...state,
				vehicleOffencePayment: action.payload
			};
		case SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH:
			return {
				...state,
				vehicleOffencePaymentBySearch: action.payload
			};
		case SET_VEHICLE_OFFENCE_PAYMENT_BY_ID:
			return {
				...state,
				vehicleOffencePaymentById: action.payload
			};
		case SET_VEHICLE_OFFENSE_PAYMENT_STATUSES:
			return {
				...state,
				vehicleOffencePaymentStatuses: action.payload
			};
		case SET_VEHICLE_DETAIL_CONFLICT:
			return {
				...state,
				vehicleDetailConflicts: action.payload
			};
		case SET_VEHICLE_DETAIL_CONFLICT_BY_SEARCH:
			return {
				...state,
				vehicleDetailConflictsBySearch: action.payload
			};
		case SET_VEHICLE_DETAIL_CONFLICT_RESOLUTIONS:
			return {
				...state,
				resolutionStatus: action.payload
			};
		case SET_OFFENCE_LIST:
			return {
				...state,
				offenceList: action.payload
			};
		case SET_OFFENCES_BY_SEARCH:
			return {
				...state,
				offencesBySearch: action.payload
			};
		case SET_CITIES:
			return {
				...state,
				cities: action.payload
			};
		case SET_STATES:
			return {
				...state,
				states: action.payload
			};
		case SET_CAR_BRANDS:
			return {
				...state,
				carBrands: action.payload
			};
		case SET_OFFENCE_CATEGORIES:
			return {
				...state,
				offenceCategories: action.payload
			};
		case SET_DRIVER_LICENCES:
			return {
				...state,
				driverLicences: action.payload
			};
		case SET_DRIVER_LICENCES_BY_SEARCH:
			return {
				...state,
				driverLicencesBySearch: action.payload
			};
		case CLEAR_DRIVER_LICENCES_BY_SEARCH:
			return {
				...state,
				driverLicencesBySearch: []
			};
		case SET_VEHICLE_FLAGS:
			return {
				...state,
				vehicleFlags: action.payload
			};
		case SET_ALL_VEHICLE_OFFENSES:
			return {
				...state,
				vehicleOffenses: action.payload
			};
		case SET_VEHICLE_OFFENCE_BY_SEARCH:
			return {
				...state,
				vehicleOffencesBySearch: action.payload
			};
		case SET_VEHICLE_OFFENCE_THUMBNAILS:
			return {
				...state,
				vehicleOffencesThumbnails: action.payload
			};
		case SET_VEHICLE_OFFENSE:
			return {
				...state,
				vehicleOffense: action.payload
			}
		case SET_VEHICLE_BROADCASTS:
			return {
				...state,
				vehicleBroadCasts: action.payload
			}
		case SET_VBC_SEARCH:
			return {
				...state,
				vehicleBroadCastsBySearch: action.payload
			}
		case SET_ALL_USER_ROLE:
			return {
				...state,
				role: action.payload
			}
		case SET_STATUS:
			return {
				...state,
				status: action.payload
			};
		case SET_ALL_CAMERAS:
			return {
				...state,
				cameras: action.payload
			};
		case SET_ALL_CONTESTS:
			return {
				...state,
				contests: action.payload
			};
		case SET_CONTEST_BY_REG_NUMBER:
			return {
				...state,
				contestByRegNumber: action.payload
			};
		case SET_CONTESTS_BY_SEARCH:
			return {
				...state,
				contestsBySearch: action.payload
			};
		case SET_CONTEST_STATUSES:
			return {
				...state,
				contestStatuses: action.payload
			};
		case SET_ALL_CASH_PAYMENTS:
			return {
				...state,
				cashPayments: action.payload
			};
		case SET_CASH_PAYMENTS_BY_SEARCH:
			return {
				...state,
				cashPaymentsBySearch: action.payload
			};
		case SET_SOT_INSURANCE_RESPONSE:
			return {
				...state,
				sotInsurance: action.payload
			};
		case SET_SOT_PAAR_RESPONSE:
			return {
				...state,
				sotPaar: action.payload
			};
		case SET_REVENUE_GROUPS:
			return {
				...state,
				revenueGroups: action.payload
			};
		case SET_INVOICE_RECONCILIATIONS:
			return {
				...state,
				iReconciliations: action.payload
			};
		case SET_INVOICE_RECONCILIATIONS_BY_SEARCH:
			return {
				...state,
				iReconciliationsBySearch: action.payload
			};
		case SET_ALL_BANKS:
			return {
				...state,
				banks: action.payload
			};
		case SET_BANKS_BY_SEARCH:
			return {
				...state,
				banksBySearch: action.payload
			};
		case SET_ALL_BANK_DEPOSIT_BATCHES:
			return {
				...state,
				bankDepositBatches: action.payload
			};
		case SET_BANK_DEPOSIT_BATCHES_BY_SEARCH:
			return {
				...state,
				bankDepositBatchesBySearch: action.payload
			};
		case SET_ALL_BANK_CASH_DEPOSITS:
			return {
				...state,
				bankCashDeposits: action.payload
			};
		case SET_BANK_CASH_DEPOSITS_BY_SEARCH:
			return {
				...state,
				bankCashDepositsBySearch: action.payload
			};
		case SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS:
			return {
				...state,
				outstandingVehicleOffencePayments: action.payload
			};
		case SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH:
			return {
				...state,
				outstandingVehicleOffencePaymentsBySearch: action.payload
			};
		case SET_VEHICLE_PROFILES:
			return {
				...state,
				vehicleProfiles: action.payload
			};
		case SET_VEHICLE_PROFILES_BY_SEARCH:
			return {
				...state,
				vehicleProfilesBySearch: action.payload
			};
		case SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES:
			return {
				...state,
				vehicleOffencePaymentsWOInvoices: action.payload
			};
		case SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH:
			return {
				...state,
				vehicleOffencePaymentsWOInvoicesBySearch: action.payload
			};
		case SET_WEBSITE_MESSAGES:
			return {
				...state,
				websiteMessages: action.payload
			};
		case GET_CITATION_INFORMATION:
			return {
				...state,
				citationInformation: action.payload
			};
		case SET_INSURANCE_INFO:
			return {
				...state,
				insuranceInformation: action.payload
			};
		case SET_WAIVER_REQUESTS:
			return {
				...state,
				waiverRequests: action.payload
			};
		case SET_WAIVER_REQUESTS_BY_SEARCH:
			return {
				...state,
				waiverRequestsBySearch: action.payload
			};
		case SET_WAIVER_REQUESTS_BY_ID:
			return {
				...state,
				waiverRequestById: action.payload
			};
		case SET_WAIVER_REQUEST_STATUSES:
			return {
				...state,
				waiverRequestStatuses: action.payload
			};
		case SET_WAIVER_DOCUMENTS:
			return {
				...state,
				waiverRequestDocument: action.payload
			};
		case SET_WAIVER_COMMENTS:
			return {
				...state,
				waiverRequestComment: action.payload
			};
		case SET_WAIVER_REQUEST_DATA:
			return {
				...state,
				waiverRequestData: action.payload
			};
		case SET_ALL_YARDS:
			return {
				...state,
				yards: action.payload
			};
		case SET_ALL_YARD_LOTS:
			return {
				...state,
				yardLots: action.payload
			};
		case SET_YARD_LOT_BY_ID:
			return {
				...state,
				yardLotById: action.payload
			};
		case SET_YARD_LOT_BY_YARD_ID:
			return {
				...state,
				yardLotsByYardId: action.payload
			};
		case SET_ALL_IMPOUNDS:
			return {
				...state,
				impounds: action.payload
			};
		case SET_IMPOUNDS_BY_SEARCH:
			return {
				...state,
				impoundsBySearch: action.payload
			};
		case SET_IMPOUND_REQUESTS:
			return {
				...state,
				impoundRequests: action.payload
			};
		case SET_IMPOUND_REQUEST_BY_ID:
			return {
				...state,
				impoundRequestById: action.payload
			};
		case SET_IMPOUND_STATUS:
			return {
				...state,
				impoundStatus: action.payload
			};
		case SET_IMPOUND_STATUS_BY_ID:
			return {
				...state,
				impoundStatusById: action.payload
			};	
		case SET_ALL_IMPOUND_RR:
			return {
				...state,
				impoundRR: action.payload
			};	
		case SET_IMPOUND_RR_BY_SEARCH:
			return {
				...state,
				impoundRRBySearch: action.payload
			};	
		case SET_TOTAL_COUNT:
			return {
				...state,
				totalCount : action.payload
			};
		case SET_TOTAL_SEARCH_COUNT:
			return {
				...state,
				totalSearchCount : action.payload
			};
		default:
			return state;
	};
};

export default adminReducer;