import {  AXIOS_REQUEST, AXIOS_REQUEST_SUCCESS, AXIOS_REQUEST_FAILURE, SET_ALL_ADMIN_ACTION_GROUPS,
    SET_SINGLE_ADMIN_ACTION_GROUP, SET_ALL_ADMIN_ACTIONS, SET_SINGLE_ADMIN_ACTION, SET_ALL_ADMIN_ROLE_ACTIONS, 
	SET_TOTAL_COUNT, SET_TOTAL_SEARCH_COUNT, SET_ADMIN_ROLE_ACTIONS_BY_SEARCH, SET_ROLES,
	SET_USER_ROLES
    } from '../actions/types'

const initialState = {
	loading: false,
	action: '',
	errors: '',
    adminActionGroups : [],
    adminActionGroup : {},
    adminActions : [],
    adminAction : {},
    adminRoleActions : [],
	adminRoleActionsBySearch: [],
	userRoles : [],
	userRolesSuccess : '',
	roles:[],
	totalCount: 0,
	totalSearchCount: 0
}

const adminRoleReducer = ( state = initialState, action : any ) => {
	switch (action.type) {
        case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
        case SET_SINGLE_ADMIN_ACTION_GROUP:
			return {
				...state,
				adminActionGroup: action.payload,
			};
        case SET_ALL_ADMIN_ACTION_GROUPS:
			return {
				...state,
				adminActionGroups: action.payload,
			};
        case SET_ALL_ADMIN_ACTIONS:
			return {
				...state,
				adminActions: action.payload,
			};
        case SET_SINGLE_ADMIN_ACTION:
			return {
				...state,
				adminAction: action.payload,
			};
        case SET_ALL_ADMIN_ROLE_ACTIONS:
			return {
				...state,
				adminRoleActions: action.payload,
			};
        case SET_ADMIN_ROLE_ACTIONS_BY_SEARCH:
			return {
				...state,
				adminRoleActionsBySearch: action.payload,
			};
        case SET_ROLES:
			return {
				...state,
				roles: action.payload,
			};
		case SET_USER_ROLES:
			return {
				...state,
				userRoles: action.payload.result,
				userRolesSuccess : action.payload.message
			};
		case SET_TOTAL_COUNT:
			return {
				...state,
				totalCount : action.payload
			};
		case SET_TOTAL_SEARCH_COUNT:
			return {
				...state,
				totalSearchCount : action.payload
			};
		default:
			return state;
    }
}

export default adminRoleReducer;