import axios, { AxiosResponse } from 'axios';
import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl, pageurl } from '../../../utils/constants';
import { decodedToken } from "../../../utils/token";
import { CLEAR_NOTIFICATION, SET_ADMIN_AUTHENTICATED, SET_ADMIN_ROLES, SET_ADMIN_UNAUTHENTICATED, SET_TABLE_INDEX } from '../types';

import { generateInvoiceId, getCashPayments, getCashPaymentsBySearch, clearCashPaymentBySearch, syncCashPayment, getRevenueGroups, getIReconciliations, getIReconciliationsBySearch, clearIReconciliationsBySearch, getAllBanks, createBank, getBanksBySearch, clearBanksBySearch, getBankDepositBatches, getBankDepositBatchesBySearch, clearBankDepositBatchesBySearch, getBankCashDeposits, getBankCashDepositsBySearch, createBankCashDeposits, createBankDepositBatch, clearBankCashDepositsBySearch, getVehicleOffencePaymentsWithoutInvoices, getVehicleOffencePaymentsWithoutInvoicesBySearch, clearVehicleOffencePaymentsWithoutInvoicesBySearch, reconcileInvoice, revertCashPayment, fixDuplicateInvoice } from './finance';

import { getContests, getContestsBySearch, clearContestsBySearch, getContestsByRegNumber, clearContestByRegNumber, updateContest, getContestStatuses } from './contests';

import { getAllDevices, getDevicesBySearch, clearDevicesBySearch, getAllDeviceUsers, addDevice, assignDevice } from './devices';

import { getDriverLicences, getDriverLicencesBySearch, clearDriversLicencesBySearch, createDriverLicence } from './licences';

import { getStates, getCities, createCountry, createState, createCity } from './location';

import { getOffenceList, getOffencesBySearch, clearOffencesBySearch, createOffence, getOffenceCategories, createOffenceCategory } from './offences';

import { getAllVehicles, getVehicleBySearch, getVehicleById, clearVehicleBySearch, clearVehicleById, addNewVehicle, getVehicleModels, addVehicleModel, getVehicleContact, updateVehiclePlateNo, getCarBrands, createCarBrand, getVehicleFlags, createVehicleFlag, createVehicleBroadcast, getVehicleBroadCasts, getVBCSearch, createVehicleCrash, getVehicleCrashes, getVehicleDetailConflict, getVehicleDetailConflictBySearch, clearVehicleDetailConflictBySearch, getVehicleProfiles, getVehicleProfilesBySearch, clearVehicleProfilesBySearch, getResolutionStatus, updateResolutionStatus } from './vehicle';

import { getAllVehicleOffences, getVehicleOffenceBySearch, getVehicleOffenceById, clearVehicleOffenceBySearch, clearVehicleOffenceById, addNewVehicleOffence, getAllVehicleOffencePayment, getVehicleOffencePaymentBySearch, 	getVehicleOffencePaymentById, clearVehicleOffencePaymentBySearch, clearVehicleOffencePaymentById, getOutstandingVehicleOffencePayments, getOutstandingVehicleOffencePaymentsBySearch, clearOutstandingVehicleOffencePaymentsBySearch, getAllVehicleOffencePaymentStatus } from './vehicle-offence';

import { getOrganizations, addOrganization } from './organization';
import { getAllTeams, addNewTeam, assignDeviceToTeam, getTeamDevices } from './teams';

import { getAllPersons, getPersonBySearch, clearPersonBySearch, getPersonOffences, getPersonOffenceBySearch, clearPersonOffenceBySearch, getPersonOffencePayment, getPersonOffencePaymentBySearch, getPersonOffencePaymentById, clearPersonOffencePaymentBySearch, getPersonContact, getPersonBroadCasts, createPersonBroadCast, addPerson } from './person';

import { getAllUsers, addNewUser, changePassword, disableUser, updateUserRole } from './user';

import { sendSMSNotification, sendWhatsappNotification, sendCallNotification, bookVehicle, issueWarning, SOTInsuranceCheck, clearSOTInsuranceCheck, SOTPaarCheck, clearSOTPAARCheck } from './SOT';

import { createStatus, getStatus, addNewCamera, getCameras } from './others';

import { getWebsiteMessages, deleteWebsiteMessage, getCitationInformation, getResponseEmails, sendResponseEmail, getInsuranceInfo, clearInsuranceInfo, getWaiverRequests, getWaiverRequestsBySearch, getWaiverRequestById, clearWaiverRequestsBySearch, clearWaiverRequestById, createWaiver, deleteWaiverRequest, getWaiverRequestStatuses, createWaiverRequestStatus, uploadWaiverDocument, getWaiverDocuments, deleteWaiverDocument, getWaiverComments, addWaiverComment, deleteWaiverComment, approveWaiver } from './support';

import { getYards, createYard, getYardLots, getYardLotById, clearYardLotById, createYardLot, createImpountInterest, getImpoundRequestById, getImpoundRequest, createImpoundStatus, getImpoundStatusById, getImpoundStatus, getImpounds, getImpoundsBySearch, createImpoundReleaseRequest, getImpoundRR, getImpoundRRBySearch, createImpound, approveImpoundReleaseRequest,getYardLotByYardId, clearYardLotByYardId } from './impounds';
import { getRequest, postRequest } from 'store/services';


export const isDefined = ( pageNumber : number | undefined, pageSize : number | undefined  ) => {
	return pageNumber !== undefined && pageSize !== undefined  
};

const headers = {'organisationId': decodedToken!?.role}

const setAuthorizationHeader = (token: string) => {
	const TrafficManagementToken = `Bearer ${token}`;
	localStorage.setItem("TrafficManagementToken", TrafficManagementToken);
	axios.defaults.headers.common["Authorization"] = TrafficManagementToken;
};

const clearNotification = (  ) => ( dispatch : Function ) =>{
	dispatch({ type: CLEAR_NOTIFICATION })
};

const loginAdmin = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/auth/login`, actionEnum: ActionEnums.LOGIN,
		data, disPatch:[{ type : SET_ADMIN_AUTHENTICATED }],
		extraFunc: (res: AxiosResponse<any>) => {res.status === 200 ? window.location.href = pageurl.TMADMIN : void(0); setAuthorizationHeader(res.data.token) }
	});

const redirectToLogin = () => 
	getRequest({ url: '', actionEnum: ActionEnums.LOGIN, extraFunc: () => console.log('boom') });

const setTableIndex = (index: number) => (dispatch: Function) => {
	dispatch({
		type: SET_TABLE_INDEX,
		payload: index
	})
};

const getAdminRoles = (id: string) => 
	getRequest({
		url: `${etraffica_baseurl}/roleaction/role`,
		actionEnum: ActionEnums.SET_ADMIN_ROLES, id: parseInt(id),
		disPatch: [{type: SET_ADMIN_ROLES, payload: 'data' }]
	});

const logOutAdmin = () => (dispatch: Function) => {
	localStorage.removeItem('TrafficManagementToken');
	dispatch({ type: SET_ADMIN_UNAUTHENTICATED });
	delete axios.defaults.headers.common["Authorization"];
	localStorage.clear();
	window.location.href = pageurl.TMLOGIN;
};

export { 
headers, setAuthorizationHeader, loginAdmin, getAdminRoles, setTableIndex, logOutAdmin, clearNotification, redirectToLogin,

//finance
generateInvoiceId, getCashPayments, getCashPaymentsBySearch, clearCashPaymentBySearch, syncCashPayment, getRevenueGroups, getIReconciliations, getIReconciliationsBySearch, clearIReconciliationsBySearch, getAllBanks, createBank, getBanksBySearch, clearBanksBySearch, getBankDepositBatches, getBankDepositBatchesBySearch, clearBankDepositBatchesBySearch, getBankCashDeposits, getBankCashDepositsBySearch, createBankCashDeposits, createBankDepositBatch, clearBankCashDepositsBySearch, getVehicleOffencePaymentsWithoutInvoices, getVehicleOffencePaymentsWithoutInvoicesBySearch, clearVehicleOffencePaymentsWithoutInvoicesBySearch, reconcileInvoice, revertCashPayment, fixDuplicateInvoice,

//contests
getContests, getContestsBySearch, clearContestsBySearch, getContestsByRegNumber, clearContestByRegNumber, updateContest, getContestStatuses,

//devices
getAllDevices, getDevicesBySearch, clearDevicesBySearch, getAllDeviceUsers, addDevice, assignDevice,

//licences
getDriverLicences, getDriverLicencesBySearch, clearDriversLicencesBySearch, createDriverLicence,

//location
getStates, getCities, createCountry, createState, createCity,

//offences
getOffenceList, getOffencesBySearch, clearOffencesBySearch, createOffence, getOffenceCategories, createOffenceCategory,

//vehicles
getAllVehicles, getVehicleBySearch, getVehicleById, clearVehicleBySearch, clearVehicleById, addNewVehicle, getVehicleModels, addVehicleModel, getVehicleContact, updateVehiclePlateNo, getCarBrands, createCarBrand, getVehicleFlags, createVehicleFlag, createVehicleBroadcast, getVehicleBroadCasts, getVBCSearch, createVehicleCrash, getVehicleCrashes, getVehicleDetailConflict, getVehicleDetailConflictBySearch, clearVehicleDetailConflictBySearch, getVehicleProfiles, getVehicleProfilesBySearch, clearVehicleProfilesBySearch, getResolutionStatus, updateResolutionStatus,

//vehicle-offences
getAllVehicleOffences, getVehicleOffenceBySearch, getVehicleOffenceById, clearVehicleOffenceBySearch, clearVehicleOffenceById, addNewVehicleOffence, getAllVehicleOffencePayment, getVehicleOffencePaymentBySearch, getVehicleOffencePaymentById, clearVehicleOffencePaymentBySearch, clearVehicleOffencePaymentById, getOutstandingVehicleOffencePayments, getOutstandingVehicleOffencePaymentsBySearch, clearOutstandingVehicleOffencePaymentsBySearch, getAllVehicleOffencePaymentStatus,

//organization
getOrganizations, addOrganization,

//teams
getAllTeams, addNewTeam, assignDeviceToTeam, getTeamDevices ,

//persons
getAllPersons, getPersonBySearch, clearPersonBySearch, getPersonOffences, getPersonOffenceBySearch, clearPersonOffenceBySearch, getPersonOffencePayment, getPersonOffencePaymentBySearch, getPersonOffencePaymentById, clearPersonOffencePaymentBySearch, getPersonContact, getPersonBroadCasts, createPersonBroadCast, addPerson,

//SOT
sendSMSNotification, sendWhatsappNotification, sendCallNotification, bookVehicle, issueWarning, SOTInsuranceCheck, clearSOTInsuranceCheck, SOTPaarCheck, clearSOTPAARCheck,

//user
getAllUsers, addNewUser, changePassword, disableUser, updateUserRole,

//others
createStatus, getStatus, addNewCamera, getCameras,

//support
getWebsiteMessages, deleteWebsiteMessage, getCitationInformation, getResponseEmails, sendResponseEmail, getInsuranceInfo, clearInsuranceInfo, getWaiverRequests, getWaiverRequestsBySearch, getWaiverRequestById, clearWaiverRequestsBySearch, clearWaiverRequestById, createWaiver, deleteWaiverRequest, getWaiverRequestStatuses, createWaiverRequestStatus, uploadWaiverDocument, getWaiverDocuments, deleteWaiverDocument, getWaiverComments, addWaiverComment, deleteWaiverComment,
approveWaiver,

//impounds
getYards, createYard, getYardLots, getYardLotById, clearYardLotById, createYardLot, createImpountInterest, getImpoundRequestById, getImpoundRequest, createImpoundStatus, getImpoundStatusById, getImpoundStatus, getImpounds, getImpoundsBySearch, createImpoundReleaseRequest,
getImpoundRR, getImpoundRRBySearch, createImpound, approveImpoundReleaseRequest, getYardLotByYardId, clearYardLotByYardId
};


// const loginAdmin = (adminDetails: object) => (dispatch: Function) => {
// 	dispatch({type: AXIOS_REQUEST, payload : ActionEnums.LOGIN })
// 	axios({url: `${etraffica_baseurl}/auth/login`, method:'post', data: adminDetails, validateStatus : ()=> true })
// 		.then((res) => {
// 			const { token } = res.data;
// 			setAuthorizationHeader(token);
// 			dispatch({type: SET_ADMIN_AUTHENTICATED})
// 			dispatch({type: AXIOS_REQUEST_SUCCESS })
// 			res.status === 200 ? window.location.href = pageurl.TMADMIN : void(0);
// 			getHttpSuccess( dispatch, ActionEnums.LOGIN, res, HttpMethod.POST )
// 		})
// 		.catch((err) => {
// 			dispatch({
// 				type: AXIOS_REQUEST_FAILURE,
// 				payload: err.message,
// 			});
// 			getHttpError( err, dispatch, ActionEnums.LOGIN, HttpMethod.POST )
// 		});
// };