
export enum AdminActions {
    // Overview
    view_overview = 'view_overview',
    overview_view_critical_actions = 'overview_view_critical_actions',
    overview_view_dashboards = 'overview_view_dashboards',
    overview_view_daily_report = 'overview_view_daily_report',
    overview_view_booking_performance = 'overview_view_booking_performance',
    overview_view_terminal_performance = 'overview_view_terminal_performance',
    overview_view_terminal_utilization = 'overview_view_terminal_utilization',
    overview_view_terminal_cash_recovery = 'overview_view_terminal_cash_recovery',
    ///
    overview_view_cm_booking = 'overview_view_cm_booking',
    overview_view_cm_payment = 'overview_view_cm_payment',
    overview_view_pm_booking = 'overview_view_pm_booking',
    overview_view_pm_payment = 'overview_view_pm_payment',
    overview_view_booking_by_count = 'overview_view_booking_by_count',
    overview_view_revenue_by_count = 'overview_view_revenue_by_count',
    overview_view_booking_by_amount = 'overview_view_booking_by_amount',
    overview_view_revenue_by_amount = 'overview_view_revenue_by_amount',
    overview_view_overall_terminal_performance = 'overview_view_overall_terminal_performance',
    overview_view_terminal_recovery_ratio = 'overview_view_terminal_recovery_ratio',
    overview_view_scheduler_recovery_ratio = 'overview_view_scheduler_recovery_ratio',
    overview_view_financial_metrics = 'overview_view_financial_metrics',
    overview_view_average_pl_per_location = 'overview_view_average_pl_per_location',
    overview_view_pl_minna = 'overview_view_pl_minna',
    overview_view_pl_suleja = 'overview_view_pl_suleja',
    overview_view_political_stakeholders = 'overview_view_political_stakeholders',
    overview_view_minna_booking_vs_payment = 'overview_view_minna_booking_vs_payment',
    overview_view_suleja_booking_vs_payment = 'overview_view_suleja_booking_vs_payment',
    overview_view_minna_booking_frequency = 'overview_view_minna_booking_frequency',
    overview_view_suleja_booking_frequency = 'overview_view_suleja_booking_frequency',
    overview_view_profiling_performance = 'overview_view_profiling_performance',
    overview_view_offence_contact = 'overview_view_offence_contact',
    
    //Instance Logger
    instance_log_view_list = 'instance_log_view_list',
    instance_log_view_details = 'instance_log_view_details',
    instance_log_view_vehicle_instances = 'instance_log_view_vehicle_instances',
    instance_log_view_vehicle_violations = 'instance_log_view_vehicle_violations',
    instance_log_view_vehicle_warnings = 'instance_log_view_vehicle_warnings',
    instance_log_book_vehicle = 'instance_log_book_vehicle',
    instance_log_send_owner_message = 'instance_log_send_owner_message',
    instance_log_send_owner_warning = 'instance_log_send_owner_warning',
    instance_log_search_filter = 'instance_log_search_filter',

    //Vehicles
    vehicle_view = 'vehicle_view',
    vehicle_create = 'vehicle_create',
    vehicle_update = 'vehicle_update',
    vehicle_delete = 'vehicle_delete',
    vehicle_search_filter = 'vehicle_search_filter',
    vehicle_broadcast_view = 'vehicle_broadcast_view',
    vehicle_broadcast_create = 'vehicle_broadcast_create',
    vehicle_broadcast_update = 'vehicle_broadcast_update',
    vehicle_broadcast_delete = 'vehicle_broadcast_delete',
    vehicle_crash_view = 'vehicle_crash_view',
    vehicle_broadcast_search_filter = 'vehicle_broadcast_search_filter',
    vehicle_offence_search_filter = 'vehicle_offence_search_filter',
    vehicle_offence_view_details = 'vehicle_offence_view_details',
    vehicle_offence_view_owner = 'vehicle_offence_view_owner',
    vehicle_offence_view = 'vehicle_offence_view',
    vehicle_offence_create_offence = 'vehicle_offence_create_offence',
    vehicle_offence_update_offence = 'vehicle_offence_update_offence',
    vehicle_offence_delete_offence = 'vehicle_offence_delete_offence',
    vehicle_offence_payment_view = 'vehicle_offence_payment_view',
    vehicle_car_brand_view = 'vehicle_car_brand_view',
    vehicle_car_brand_create = 'vehicle_car_brand_create',
    vehicle_flag_view = 'vehicle_flag_view',
    vehicle_flag_create = 'vehicle_flag_create',
    vehicle_model_view = 'vehicle_model_view',
    vehicle_model_create = 'vehicle_model_create',
    //not created
    vehicle_detail_conflict_view = 'vehicle_detail_conflict_view',
    vehicle_view_profiles = 'vehicle_view_profiles',

    //Licence
    licence_view = 'licence_view',
    licence_create = 'licence_create',
    licence_update = 'licence_update',
    licence_delete = 'licence_delete',
    licence_create_broadcast = 'licence_create_broadcast',

    //Person
    person_view = 'person_view',
    person_more_details = 'person_more_details',
    person_update = 'person_update',
    person_delete = 'person_delete',
    person_create = 'person_create',
    person_search_filter = 'person_search_filter',
    person_broadcast_view = 'person_broadcast_view',
    person_broadcast_create = 'person_broadcast_create',
    person_broadcast_update = 'person_broadcast_update',
    peron_broadcast_delete = 'peron_broadcast_delete',
    person_broadcast_search_filter = 'person_broadcast_search_filter',
    person_offence_search_filter = 'person_offence_search_filter',
    person_offence_view_details = 'person_offence_view_details',
    person_offence_view_owner = 'person_offence_view_owner',
    person_offence_view = 'person_offence_view',
    person_offence_create_offence = 'person_offence_create_offence',
    person_offence_update_offence = 'person_offence_update_offence',
    person_offence_delete_offence = 'person_offence_delete_offence',
    person_offence_payment_view = 'person_offence_payment_view',

    //Finance
    finance_view_paid_offences = 'finance_view_paid_offences',
    finance_view_total_violations = 'finance_view_total_violations',
    finance_view_unpaid_offences = 'finance_view_unpaid_offences',
    finance_view_cash_transactions = 'finance_view_cash_transactions',
    finance_view_invoice_reconciliations = 'finance_view_invoice_reconciliations',
    finance_view_bank = 'finance_view_bank',
    finance_view_bank_cash_deposits = 'finance_view_bank_cash_deposits',
    finance_view_bank_deposit_batches = 'finance_view_bank_deposit_batches',
    finance_view = 'finance_view',
    finance_view_vehicle_offence_payments_wo_invoice = 'finance_view_vehicle_offence_payments_wo_invoice',
    finance_reconcile_invoice = 'finance_reconcile_invoice',
    finance_revert_cash_payment = 'finance_revert_cash_payment',

    //Offences
    offences_view_offence = 'offences_view_offence',
    offences_update_offence = 'offences_update_offence',
    offences_delete_offence = 'offences_delete_offence',
    offences_create_offence = 'offences_create_offence',
    offences_search_filter = 'offences_search_filter',
    offences_view_offences_category = 'offences_view_offences_category',

    //Management
    management_view = 'management_view',
    management_view_users = 'management_view_users',
    management_create_user = 'management_create_user',
    management_update_user = 'management_update_user',
    management_delete_user = 'management_delete_user',
    management_update_user_role = 'management_update_user_role',
    management_users_search_filter = 'management_users_search_filter',
    management_view_devices = 'management_view_devices',
    management_create_devices = 'management_create_devices',
    management_update_devices = 'management_update_devices',
    management_delete_devices = 'management_delete_devices',
    management_devices_search_filter = 'management_devices_search_filter',
    management_link_device_to_user = 'management_link_device_to_user',
    management_view_user_management = 'management_view_user_management',
    management_view_device_management = 'management_view_device_management',
    management_view_device_user = 'management_view_device_user',
    management_view_team_management = 'management_view_team_management',
    management_create_teams = 'management_create_teams',
    management_view_device_team = 'management_view_device_team',

    //Organizations
    organizations_view_organization = 'organizations_view_organization',
    organizations_create_organizations = 'organizations_create_organizations',
    organizations_update_organizations = 'organizations_update_organizations',
    organizations_delete_organizations = 'organizations_delete_organizations',
    organizations_search_filter = 'organizations_search_filter',

    //Location
    location_view = 'location_view',
    location_view_states = 'location_view_states',
    location_view_cities = 'location_view_cities',
    location_create_city = 'location_create_city',
    location_update_city = 'location_update_city',
    location_delete_city = 'location_delete_city',
    location_search_filter = 'location_search_filter',

    //Status
    status_view_status = 'status_view_status',
    status_create_status = 'status_create_status',
    status_update_status = 'status_update_status',
    status_delete_status = 'status_delete_status',
    status_search_filter = 'status_search_filter',

    //ECommerce
    ecommerce_view = 'ecommerce_view',
    ecommerce_view_service_groups = 'ecommerce_view_service_groups',
    ecommerce_create_service_group = 'ecommerce_create_service_group',
    ecommerce_update_service_group = 'ecommerce_update_service_group',
    ecommerce_view_vendors = 'ecommerce_view_vendors',
    ecommerce_create_vendor = 'ecommerce_create_vendor',
    ecommerce_update_vendor = 'ecommerce_update_vendor',
    ecommerce_view_vendor_services = 'ecommerce_view_vendor_services',
    ecommerce_create_vendor_service = 'ecommerce_create_vendor_service',
    ecommerce_update_vendor_service = 'ecommerce_update_vendor_service',
    ecommerce_view_transactions = 'ecommerce_view_transactions',
    ecommerce_create_transaction = 'ecommerce_create_transaction',
    ecommerce_view_transaction_requests = 'ecommerce_view_transaction_requests',
    ecommerce_view_accounts = 'ecommerce_view_accounts',
    ecommerce_create_account = 'ecommerce_create_account',
    ecommerce_link_account_to_device = 'ecommerce_link_account_to_device',

    //Camera
    camera_view = 'camera_view',
    camera_create = 'camera_create',

    //Contests
    contest_view = 'contest_view',

    //Roles
    roles_view = 'roles_view',
    role_view_actions = 'role_view_actions',
    role_view_action_group = 'role_view_action_group',
    roles_view_role_action = 'roles_view_role_action',
    role_create_action = 'role_create_action',

    //profile
    view_profile= 'view_profile',

    //support
    support_view = 'support_view',
    outstanding_payment_view = 'outstanding_payment_view',
    support_view_website_messages = 'support_view_website_messages',
    support_view_waiver_requests = 'support_view_waiver_requests',

    //impounds
    impounds_view = 'impounds_view',
    impounds_view_impounds = 'impounds_view_impounds',
    impounds_request_release = 'impounds_request_release',
    impounds_create_impound_status = 'impounds_create_impound_status',
    impounds_view_impound_requests = 'impounds_view_impound_requests',
    impounds_approve_impound_request = 'impounds_approve_impound_request',
    impounds_view_impound_release_requests = 'impounds_view_impound_release_requests',
    impounds_approve_impound_release_requests  = 'impounds_approve_impound_release_requests',
    impounds_view_yards = 'impounds_view_yards',
    impounds_add_yard = 'impounds_add_yard',
    impounds_view_yard_lots = 'impounds_view_yard_lots',
    impounds_add_yard_lots = 'impounds_add_yard_lots',
    impounds_view_status = 'impounds_view_status',

}