import axios, { AxiosResponse } from 'axios';
import { AXIOS_REQUEST, AXIOS_REQUEST_FAILURE, AXIOS_REQUEST_SUCCESS, SET_ALL_INSTANCES_COUNT, SET_INSTANCE_BY_SEARCH_COUNT, SET_TOTAL_COUNT, SET_TOTAL_SEARCH_COUNT } from 'store/actions/types';
import { getHttpError, getHttpSuccess } from 'store/actions/httpAction';
import { HttpMethod } from 'enums/HttpEnums';
import { decodedToken } from 'utils/token';

const token = localStorage.getItem('TrafficManagementToken');
axios.defaults.headers.common["Authorization"] = token!;
axios.defaults.headers.common["organizationId"] = decodedToken!?.role;
const headers = {
	'Access-Control-Allow-Origin': '*',
};
type Dispatch = { type: string, payload?: string };

interface IGenProps {
    url: string;
    actionEnum: string;
};

interface IGetProps extends IGenProps {
    disPatch?: Array<Dispatch>;
    extraFunc?: (res: AxiosResponse<any>) => void;
    pageNumber?: number;
    pageSize?: number;
    query?: string;
    id?: number;
    extraUrl?: string;
    noPaged?: boolean;
    isInstance?: boolean;
};

export const getRequest = (props: IGetProps) => ( dispatch: Function ) => {

    const { url = '', actionEnum = '', pageNumber, pageSize, query = '', id = 0, disPatch, extraFunc, extraUrl = '', noPaged = false, isInstance = false } = props;
    dispatch({ type: AXIOS_REQUEST, payload : actionEnum })
        axios({url: id ? url + `/${id}` : 
            `${url}${pageNumber && !noPaged ? '/paged' : ''}${pageNumber ? `?pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${query && `&${query}`}${extraUrl && `&${extraUrl}`}`, 
            method:'get', validateStatus : () => true, headers })
            .then((res) => {
                dispatch({ type: AXIOS_REQUEST_SUCCESS })
                for( let i = 0; i < disPatch!?.length; i++ ){
                    dispatch( { type: disPatch![i]?.type, payload : disPatch![i]?.payload! ? res.data[disPatch![i]?.payload!] : res.data })
                };

                dispatch({ type: query!?.length > 0 ? SET_TOTAL_SEARCH_COUNT : SET_TOTAL_COUNT, payload: res.data.totalCount || res.data.pageCount });

                isInstance && dispatch({ type: query!?.length > 0 ? SET_INSTANCE_BY_SEARCH_COUNT : SET_ALL_INSTANCES_COUNT, payload: res.data.totalCount });

                typeof extraFunc === 'function' && extraFunc(res)
                getHttpSuccess( dispatch, actionEnum, res, HttpMethod.GET )
            })
            .catch((err) => {
                dispatch({ type : AXIOS_REQUEST_FAILURE, payload : err.message });
                getHttpError( err, dispatch, actionEnum, HttpMethod.GET )
            })
};

interface IPostProps extends IGenProps {
    data: {[key: string]: any}
    update?: boolean;
    disPatch?: Array<Dispatch>;
    extraFunc?: (res: AxiosResponse<any>) => void;
}

export const postRequest = (props: IPostProps) => ( dispatch: Function ) => {

    const { url = '', actionEnum = '', data, update = false, disPatch, extraFunc } = props;
    dispatch({ type: AXIOS_REQUEST, payload : actionEnum });
    axios({url: update ? `${url}/${data.id}` : `${url}`, method: update? 'patch' : 'post', data, validateStatus : () => true, headers })
    .then((res) => {
        if(res.status === 200){
            dispatch({ type: AXIOS_REQUEST_SUCCESS })
            for( let i = 0; i < disPatch!?.length; i++ ){
                dispatch( { type: disPatch![i]?.type, payload : disPatch![i]?.payload! ? res.data[disPatch![i]?.payload!] : res.data })
            }
            typeof extraFunc === 'function' && extraFunc(res)
        }else{
            dispatch({ type: AXIOS_REQUEST_FAILURE });
        }
        getHttpSuccess( dispatch, actionEnum, res, HttpMethod.POST )
    })
    .catch((err) => {
        dispatch({ type : AXIOS_REQUEST_FAILURE, payload : err.message });
        getHttpError( err, dispatch, actionEnum, HttpMethod.POST )
    });
};

interface IDelProps extends IGenProps {
    id: number;
};

export const deleteRequest = (props: IDelProps) => ( dispatch: Function ) => {

    const { url = '', actionEnum = '', id = 0 } = props;
    dispatch({ type: AXIOS_REQUEST, payload : actionEnum });
    axios({url: `${url}/${id}`, method:'get', validateStatus : () => true, headers })
    .then((res) => {
        dispatch({ type: AXIOS_REQUEST_SUCCESS })
        getHttpSuccess( dispatch, actionEnum, res, HttpMethod.GET )
    })
    .catch((err) => {
        dispatch({ type : AXIOS_REQUEST_FAILURE, payload : err.message });
        getHttpError( err, dispatch, actionEnum, HttpMethod.GET )
    });
};