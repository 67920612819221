import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_CONTESTS, SET_CONTESTS_BY_SEARCH, SET_CONTEST_BY_REG_NUMBER, SET_CONTEST_STATUSES } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getContests = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/contest`,
		actionEnum: ActionEnums.GET_ALL_CONTESTS,
		disPatch: [{type: SET_ALL_CONTESTS, payload: 'contest' }], pageNumber, pageSize,
	});

export const getContestsBySearch = (query?: string, pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/contest`,
		actionEnum: ActionEnums.GET_CONTESTS_BY_SEARCH,
		disPatch: [{type: SET_CONTESTS_BY_SEARCH, payload: 'contest' }], pageNumber, pageSize, query
	});

export const clearContestsBySearch = () => (dispatch:Function) => {
	dispatch({ type: SET_CONTESTS_BY_SEARCH, payload: [] });
};

export const getContestsByRegNumber = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/contest/regnumber`,
		actionEnum: ActionEnums.GET_CONTEST_BY_REG_NUMBER,
		data, disPatch:[{ type : SET_CONTEST_BY_REG_NUMBER, payload : 'data' }]
	});

export const clearContestByRegNumber = () => (dispatch:Function) => {
	dispatch({ type: SET_CONTEST_BY_REG_NUMBER, payload: [] });
};

export const updateContest = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/contest/update`: '',
		actionEnum: ActionEnums.UPDATE_CONTEST, data, update
	});

export const getContestStatuses = () => 
	getRequest({
		url: `${etraffica_baseurl}/conteststatus`,
		actionEnum: ActionEnums.GET_CONTEST_STATUSES,
		disPatch: [{type: SET_CONTEST_STATUSES, payload: 'data' }]
	});