import { IMenuData, ISubMenuData } from 'interfaces/IMenu'
import dashboard_solid from 'extras/images/menu-icons/dashboard-solid.svg'
import vehicle_solid from 'extras/images/menu-icons/vehicle-solid.svg'
import logger from 'extras/images/menu-icons/logger.svg'
import management from 'extras/images/menu-icons/management.svg'
import org from 'extras/images/menu-icons/org.svg'
import finance from 'extras/images/menu-icons/finance.svg'
import location from 'extras/images/menu-icons/location.svg'
import status from 'extras/images/menu-icons/status.svg'
import offence from 'extras/images/menu-icons/offence2.svg'
// import person from 'extras/images/menu-icons/person.svg'
import license from 'extras/images/menu-icons/license.svg'
import camera from 'extras/images/menu-icons/camera.svg'
// import ecommerce from 'extras/images/menu-icons/e-commerce.svg'
import roles from 'extras/images/menu-icons/roles.svg';
import support from 'extras/images/menu-icons/support.svg';
import contest from 'extras/images/menu-icons/contest.svg';
import impound from 'extras/images/menu-icons/impound.svg';
import { url } from 'enums/Route';
import { AdminActions } from 'enums/Admins';
import { AdminDashboardTabs, AdminDashboardSubMenuTabs } from 'enums/AdminDashboardTabs';

export const menuData:Array<IMenuData> = [
    {
        id : AdminDashboardTabs.Overview,
        title : "Overview",
        isChildren : false,
        menuicon : dashboard_solid,
        url : url.OVERVIEW,
        view: AdminActions.view_overview
    },
    {
        id : AdminDashboardTabs.Vehicles,
        title : "Vehicle",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : vehicle_solid,
        toggleicon : "",
        url : "#",
        view: AdminActions.vehicle_view,
        suburls : [ url.VEHICLES, url.VEHICLE_CAR_BRANDS, url.VEHICLE_FLAG, url.VEHICLE_MODEL, url.VEHICLE_OFFENCES ]
    },
    {
        id : AdminDashboardTabs.Licences,
        title : "Licence",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : license,
        toggleicon : "",
        url : url.LICENSE,
        view: AdminActions.licence_view,
    },
    // {
    //     id : AdminDashboardTabs.Persons,
    //     title : "Person",
    //     children : ( children : Array<ISubMenuData> ) => children,
    //     isChildren : true,
    //     menuicon : person,
    //     toggleicon : "",
    //     url : "#",
    //     suburls : [ url.PERSONS, url.PERSON_OFFENCES ],
    //     view: AdminActions.person_view,
    // },
    {
        id : AdminDashboardTabs.Finance,
        title : "Finance",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : finance,
        toggleicon : "",
        url : '#',
        view: AdminActions.finance_view,
    },
    {
        id : AdminDashboardTabs.Offences,
        title : "Offence",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : offence,
        toggleicon : "",
        url : "#",
        suburls : [ url.OFFENCE, url.OFFENCE_CATEGORIES ],
        view: AdminActions.offences_view_offence,
    },
    {
        id : AdminDashboardTabs.Management,
        title : "Profile Management",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : management,
        toggleicon : "",
        url : "#",
        suburls : [ url.USER_MANAGEMENT, url.DEVICE_MANAGEMENT ],
        view: AdminActions.management_view,
    },
    {
        id : AdminDashboardTabs.Organization,
        title : "Organization",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : org,
        toggleicon : "",
        url : url.ORGANIZATION,
        view: AdminActions.organizations_view_organization,
    },
    // {
    //     id : AdminDashboardTabs.Ecommerce,
    //     title : "E-commerce",
    //     children : ( children : Array<ISubMenuData> ) => children,
    //     isChildren : true,
    //     menuicon : ecommerce,
    //     toggleicon : "",
    //     url : url.ECOMMERCE,
    //     view: AdminActions.ecommerce_view,
    // },
    {
        id : AdminDashboardTabs.Support,
        title : "Support",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : support,
        toggleicon : "",
        url : url.SUPPORT,
        view: AdminActions.support_view,
    },
    {
        id : AdminDashboardTabs.Impounds,
        title : "Impounds",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : impound,
        toggleicon : "",
        url : url.IMPOUNDS,
        view: AdminActions.impounds_view,
    },
    {
        id : AdminDashboardTabs.Location,
        title : "Location",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : location,
        toggleicon : "",
        url : url.LOCATION,
        view: AdminActions.location_view,
    },
    {
        id : AdminDashboardTabs.Roles,
        title : "Roles & Permissions",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : true,
        menuicon : roles,
        toggleicon : "",
        url : url.ROLES,
        view: AdminActions.roles_view,
    },
    {
        id : AdminDashboardTabs.InstanceLogger,
        title : "Instance Logger",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : logger,
        toggleicon : "",
        url : url.INSTANCE_LOGGER,
        view: AdminActions.instance_log_view_list,
    },
    {
        id : AdminDashboardTabs.Status,
        title : "Status",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : status,
        toggleicon : "",
        url : url.STATUS,
        view: AdminActions.status_view_status,
    },
    {
        id : AdminDashboardTabs.Camera,
        title : "Cameras",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : camera,
        toggleicon : "",
        url : url.CAMERAS,
        view: AdminActions.camera_view,
    },
    {
        id : AdminDashboardTabs.Contests,
        title : "Contests",
        children : ( children : Array<ISubMenuData> ) => children,
        isChildren : false,
        menuicon : contest,
        toggleicon : "",
        url : url.CONTESTS,
        view: AdminActions.contest_view,
    }
]

export const subMenuData:Array<ISubMenuData> = [
    { 
        id : AdminDashboardSubMenuTabs.ViewVehicles, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicles", 
        url : url.VEHICLES,
        view: AdminActions.vehicle_view
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleOffencePayment, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Offence Payment", 
        url : url.VEHICLE_OFFENCE_PAYMENT,
        view: AdminActions.vehicle_offence_payment_view
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleOffences, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Offences", 
        url : url.VEHICLE_OFFENCES,
        view: AdminActions.vehicle_offence_view
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleFlag, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Flag", 
        url : url.VEHICLE_FLAG,
        view: AdminActions.vehicle_flag_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleCarBrands, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Car Brands", 
        url : url.VEHICLE_CAR_BRANDS,
        view: AdminActions.vehicle_car_brand_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleModel, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Model", 
        url : url.VEHICLE_MODEL,
        view: AdminActions.vehicle_model_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.vehicleBroadCasts, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle BroadCasts", 
        url : url.VEHICLE_BROADCASTS,
        view: AdminActions.vehicle_broadcast_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleDetailConflict, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Detail Conflict", 
        url : url.VEHICLE_DETAIL_CONFLICT,
        view: AdminActions.vehicle_detail_conflict_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.UserManagement, 
        parentid : AdminDashboardTabs.Management, 
        title : "User Management", 
        url : url.USER_MANAGEMENT,
        view: AdminActions.management_view_user_management,
    },
    { 
        id : AdminDashboardSubMenuTabs.DeviceManagement, 
        parentid : AdminDashboardTabs.Management, 
        title : "Device Management", 
        url : url.DEVICE_MANAGEMENT,
        view: AdminActions.management_view_device_management,
    },
    { 
        id : AdminDashboardSubMenuTabs.DeviceUser, 
        parentid : AdminDashboardTabs.Management, 
        title : "Device-User", 
        url : url.DEVICE_USER,
        view: AdminActions.management_view_device_user,
    },
    { 
        id : AdminDashboardSubMenuTabs.TeamManagemennt, 
        parentid : AdminDashboardTabs.Management, 
        title : "Team Management", 
        url : url.TEAM_MANAGEMENT,
        view: AdminActions.management_view_team_management,
    },
    // { 
    //     id : AdminDashboardSubMenuTabs.DeviceTeam, 
    //     parentid : AdminDashboardTabs.Management, 
    //     title : "Device-Team", 
    //     url : url.TEAM_MANAGEMENT,
    //     view: AdminActions.management_view_device_team,
    // },
    { 
        id : AdminDashboardSubMenuTabs.ViewOffences, 
        parentid : AdminDashboardTabs.Offences, 
        title : "Offences", 
        url : url.OFFENCE,
        view: AdminActions.offences_view_offence
    },
    { 
        id : AdminDashboardSubMenuTabs.OffenceCategory, 
        parentid : AdminDashboardTabs.Offences, 
        title : "Offence Categories", 
        url : url.OFFENCE_CATEGORIES,
        view: AdminActions.offences_view_offences_category

    },
    { 
        id : AdminDashboardSubMenuTabs.ViewPersons, 
        parentid : AdminDashboardTabs.Persons, 
        title : "Persons", 
        url : url.PERSONS,
        view: AdminActions.person_view
    },
    { 
        id : AdminDashboardSubMenuTabs.PersonOffence, 
        parentid : AdminDashboardTabs.Persons, 
        title : "Person Offences", 
        url : url.PERSON_OFFENCES,
        view: AdminActions.person_offence_view,
    },
    { 
        id : AdminDashboardSubMenuTabs.PersonBroadCasts, 
        parentid : AdminDashboardTabs.Persons, 
        title : "Person Broadcasts", 
        url : url.PERSON_BROADCASTS,
        view: AdminActions.person_broadcast_view
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceServiceGroups, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Service Groups", 
        url : url.ECOMMERCE,
        view: AdminActions.ecommerce_view_service_groups
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceVendors, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Vendors", 
        url : url.ECOMMERCE_VENDORS,
        view: AdminActions.ecommerce_view_vendors
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceVendorService, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Vendor Services", 
        url : url.ECOMMERCE_VENDOR_SERVICES,
        view: AdminActions.ecommerce_view_vendor_services
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceAccounts, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Accounts", 
        url : url.ECOMMERCE_ACCOUNTS,
        view: AdminActions.ecommerce_view_accounts
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceTransactions, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Transactions", 
        url : url.ECOMMERCE_TRANSACTION,
        view: AdminActions.ecommerce_view_transactions
    },
    { 
        id : AdminDashboardSubMenuTabs.VehicleCrashes, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Crashes", 
        url : url.VEHICLE_CRASH,
        view: AdminActions.vehicle_crash_view
    },
    { 
        id : AdminDashboardSubMenuTabs.EcommerceTransactionRequests, 
        parentid : AdminDashboardTabs.Ecommerce, 
        title : "Transaction Requests", 
        url : url.ECOMMERCE_TRANSACTION_REQUESTS,
        view: AdminActions.ecommerce_view_transaction_requests
    },
    { 
        id : AdminDashboardSubMenuTabs.PersonOffencePayments, 
        parentid : AdminDashboardTabs.Persons, 
        title : "Person Offence Payments", 
        url : url.PERSON_OFFENCE_PAYMENT,
        view: AdminActions.person_offence_payment_view
    },
    { 
        id : AdminDashboardSubMenuTabs.AdminActions, 
        parentid : AdminDashboardTabs.Roles, 
        title : "Actions", 
        url : url.ADMIN_ACTION,
        view: AdminActions.role_view_actions
    },
    { 
        id : AdminDashboardSubMenuTabs.AdminActionGroups, 
        parentid : AdminDashboardTabs.Roles, 
        title : "Action Group", 
        url : url.ADMIN_ACTION_GROUP,
        view: AdminActions.role_view_action_group
    },
    { 
        id : AdminDashboardSubMenuTabs.AdminRoles, 
        parentid : AdminDashboardTabs.Roles, 
        title : "Roles", 
        url : url.ADMIN_ROLES,
        view: AdminActions.role_create_action
    },  
    { 
        id : AdminDashboardSubMenuTabs.AdminRoleActions, 
        parentid : AdminDashboardTabs.Roles, 
        title : "Role Actions", 
        url : url.ADMIN_ROLE_ACTIONS,
        view: AdminActions.roles_view_role_action
    },    
    { 
        id : AdminDashboardSubMenuTabs.CashTransactions, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Cash Transactions", 
        url : url.CASH_TRANSACTIONS,
        view: AdminActions.finance_view_cash_transactions
    },   
    { 
        id : AdminDashboardSubMenuTabs.InvoiceReconciliations, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Invoice Reconciliations", 
        url : url.INVOICE_RECONCILIATIONS,
        view: AdminActions.finance_view_invoice_reconciliations
    },   
    { 
        id : AdminDashboardSubMenuTabs.Banks, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Banks", 
        url : url.BANKS,
        view: AdminActions.finance_view_bank
    },   
    { 
        id : AdminDashboardSubMenuTabs.BankDepositBatch, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Bank Deposit Batches", 
        url : url.BANK_DEPOSIT_BATCHES,
        view: AdminActions.finance_view_bank_deposit_batches
    },   
    { 
        id : AdminDashboardSubMenuTabs.BankCashDeposit, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Bank Cash Deposits", 
        url : url.BANK_CASH_DEPOSITS,
        view: AdminActions.finance_view_bank_cash_deposits
    },   
    { 
        id : AdminDashboardSubMenuTabs.BankCashDeposit, 
        parentid : AdminDashboardTabs.Support, 
        title : "Outstanding Payments", 
        url : url.OUTSTANDING_PAYMENT,
        view: AdminActions.outstanding_payment_view
    },   
    // { 
    //     id : AdminDashboardSubMenuTabs.WebsiteMessages, 
    //     parentid : AdminDashboardTabs.Support, 
    //     title : "Website Messages", 
    //     url : url.WEBSITE_MESSAGES,
    //     view: AdminActions.support_view_website_messages
    // },   
    { 
        id : AdminDashboardSubMenuTabs.VehicleProfiles, 
        parentid : AdminDashboardTabs.Vehicles, 
        title : "Vehicle Profiles", 
        url : url.VEHICLE_PROFILES,
        view: AdminActions.vehicle_view_profiles
    },   
    { 
        id : AdminDashboardSubMenuTabs.VehicleOffencePaymentWOInvoice, 
        parentid : AdminDashboardTabs.Finance, 
        title : "Vehicle Offence Payments w/o invoices", 
        url : url.VOP_WO_INVOICE,
        view: AdminActions.finance_view_vehicle_offence_payments_wo_invoice
    },   
    { 
        id : AdminDashboardSubMenuTabs.WaiverRequests, 
        parentid : AdminDashboardTabs.Support, 
        title : "Waivers", 
        url : url.WAIVER_REQUESTS,
        view: AdminActions.support_view_waiver_requests
    },   
    { 
        id : AdminDashboardSubMenuTabs.Impounds, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Impounds", 
        url : url.IMPOUNDS,
        view: AdminActions.impounds_view_impounds
    },   
    { 
        id : AdminDashboardSubMenuTabs.ImpoundRequests, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Impound Requests", 
        url : url.IMPOUND_REQUESTS,
        view: AdminActions.impounds_view_impound_requests
    },   
    { 
        id : AdminDashboardSubMenuTabs.ImpoundReleaseRequests, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Impound Release Requests", 
        url : url.IMPOUND_RELEASE_REQUESTS,
        view: AdminActions.impounds_view_impound_release_requests
    }, 
    { 
        id : AdminDashboardSubMenuTabs.ImpoundStatus, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Impound Status", 
        url : url.IMPOUND_STATUS,
        view: AdminActions.impounds_view_status
    },   
    { 
        id : AdminDashboardSubMenuTabs.Yards, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Yards", 
        url : url.YARD,
        view: AdminActions.impounds_view_yards
    },   
    { 
        id : AdminDashboardSubMenuTabs.YardLots, 
        parentid : AdminDashboardTabs.Impounds, 
        title : "Yard Lots", 
        url : url.YARD_LOTS,
        view: AdminActions.impounds_view_yard_lots
    },   
]