import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_PERSONS, SET_PERSON_BROADCASTS, SET_PERSON_BY_SEARCH, SET_PERSON_CONTACT, SET_PERSON_OFFENCES, SET_PERSON_OFFENCES_BY_SEARCH, SET_PERSON_OFFENCE_PAYMENT, SET_PERSON_OFFENCE_PAYMENT_BY_ID, SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllPersons = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/person`,
		actionEnum: ActionEnums.GET_ALL_PERSONS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_PERSONS, payload: 'persons' }]
	});

export const getPersonBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/person`,
		actionEnum: ActionEnums.GET_PERSON_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_PERSON_BY_SEARCH, payload: 'persons' }]
	});

export const clearPersonBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_PERSON_BY_SEARCH, payload: {} } )
};

export const getPersonOffences = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/personoffense`,
		actionEnum: ActionEnums.GET_PERSON_OFFENCES, pageNumber, pageSize,
		disPatch: [{type: SET_PERSON_OFFENCES, payload: 'personOffenses' }]
	});

export const getPersonOffenceBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/personoffense`,
		actionEnum: ActionEnums.GET_PERSON_OFFENCE_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_PERSON_OFFENCES_BY_SEARCH, payload: 'personOffenses' }]
	});

export const clearPersonOffenceBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_PERSON_OFFENCES_BY_SEARCH, payload: [] } )
};

export const getPersonOffencePayment = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offensePayment/person`,
		actionEnum: ActionEnums.GET_PERSON_OFFENCE_PAYMENT, pageNumber, pageSize,
		disPatch: [{type: SET_PERSON_OFFENCE_PAYMENT, payload: 'data' }]
	});

export const getPersonOffencePaymentBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offensePayment/person`,
		actionEnum: ActionEnums.GET_PERSON_OFFENCE_PAYMENT_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH, payload: 'data' }]
	});

export const getPersonOffencePaymentById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/person`,
		actionEnum: ActionEnums.GET_PERSON_OFFENCE_PAYMENT_BY_ID, id,
		disPatch: [{type: SET_PERSON_OFFENCE_PAYMENT_BY_ID, payload: '' }]
	});

export const clearPersonOffencePaymentBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_PERSON_OFFENCE_PAYMENT_BY_SEARCH, payload: [] } )
};

export const getPersonContact = ( data : object) => 
	postRequest({
		url:`${etraffica_baseurl}/personoffensecontact/violation`,
		actionEnum: ActionEnums.GET_PERSON_CONTACT, data,
		disPatch:[{type: SET_PERSON_CONTACT, payload: '' }]
	});

export const getPersonBroadCasts = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/broadcast/person`,
		actionEnum: ActionEnums.GET_PERSON_BROADCAST, pageNumber, pageSize,
		disPatch: [{type: SET_PERSON_BROADCASTS, payload: 'personBroadcast' }]
	});

export const createPersonBroadCast = ( data : object, update?: boolean) => 
	postRequest({
		url: update? `${etraffica_baseurl}/broadcast/person/update` : `${etraffica_baseurl}/broadcast/person`,
		actionEnum: update ? ActionEnums.UPDATE_PERSON_BROADCAST : ActionEnums.CREATE_PERSON_BROADCAST, data, update
	});

export const addPerson = ( data : object, update?: boolean) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/person/update` : `${etraffica_baseurl}/person`,
		actionEnum: update ? ActionEnums.UPDATE_PERSON : ActionEnums.ADD_PERSON, data, update
	});