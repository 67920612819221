import {
	AXIOS_REQUEST,
	AXIOS_REQUEST_FAILURE,
	AXIOS_REQUEST_SUCCESS,
	SET_ALL_INSTANCES,
	SET_INSTANCE_BY_SEARCH,
	CLEAR_INSTANCE_BY_SEARCH,
	SET_ALL_INSTANCES_COUNT,
	SET_INSTANCE_BY_SEARCH_COUNT,
	SET_INSTANCE_THUMBNAILS,
	SET_INSTANCE_BY_ID
} from "../actions/types";

const initialState = {
	loading: false,
	action: '',
	errors: '',
	data: [],
	instances: [],
	instancesBySearch: [],
	instanceById: {},
	totalCount: 0,
	totalSearchCount: 0,
	instanceThumbNails:[]
};

const instanceLoggerReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		case SET_ALL_INSTANCES:
			return {
				...state,
				instances: action.payload
			};
		case SET_INSTANCE_BY_SEARCH:
			return {
				...state,
				instancesBySearch : action.payload
			};
		case SET_INSTANCE_THUMBNAILS:
			return {
				...state,
				instanceThumbNails : action.payload
			};
		case CLEAR_INSTANCE_BY_SEARCH:
			return {
				...state,
				instancesBySearch : []
			};
		case SET_INSTANCE_BY_ID:
			return {
				...state,
				instanceById : action.payload
			};
		case SET_ALL_INSTANCES_COUNT:
			return {
				...state,
				totalCount : action.payload
			};
		case SET_INSTANCE_BY_SEARCH_COUNT:
			return {
				...state,
				totalSearchCount : action.payload
			};
		default:
			return state;
	};
};

export default instanceLoggerReducer;