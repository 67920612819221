import React from 'react';
import { IUrl } from '../../interfaces/IAdmin'
import { url } from '../../enums/Route'
import { AdminActions } from '../../enums/Admins';

const Login = React.lazy(() => import('../../components/admin'));
const Overview = React.lazy(() => import('../../components/dashboard/overview'));
const AdminProfile = React.lazy(() => import('../../components/admin/profile'));
const Vehicles = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicles/view-vehicles'))
const CarBrands = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/car-brands/view-car-brands'))
const VehicleFlag = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-flags/view-vehicle-flags'))
const VehicleCrash = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-crash/view-vehicle-crash'))
const VehicleModel = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-model/view-vehicle-models'))
const VehicleOffenses = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-offenses/view-vehicles-offense'))
const VehicleOffensesPayment = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-offence-payment/view-vehicle-offence-payment'));
const VehicleProfiles = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-profile/view-vehicle-profiles'));
const VehicleOffencePaymentWOInvoice = React.lazy(() => import('../../components/dashboard/finance/offences-without-invoices'));
const VehicleDetailConflict = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-detail-conflict/view-vehicle-detail-conflict'))
const Organization = React.lazy(() => import('../../components/dashboard/system-actions/organizations/view-organizations'))
const Persons = React.lazy(() => import('../../components/dashboard/system-actions/persons/view-persons'))
const PersonOffence = React.lazy(() => import('../../components/dashboard/system-actions/persons/person-offenses/view-person-offense'))
const PersonOffencePayment = React.lazy(() => import('../../components/dashboard/system-actions/persons/person-offenses/view-person-offense-payment'))
const Finance = React.lazy(() => import('../../components/dashboard/finance'))
const CashTransactions = React.lazy(() => import('../../components/dashboard/finance/view-cash-transaction'))
const Offenses = React.lazy(() => import('../../components/dashboard/system-actions/offences/view-offences'))
const OffenseCategory = React.lazy(() => import('../../components/dashboard/system-actions/offences/offence-category/view-offence-category'))
const UserManagement = React.lazy(() => import('../../components/dashboard/user-management/index'))
const DeviceManagement = React.lazy(() => import('../../components/dashboard/device-management/index'))
const DeviceUser = React.lazy(() => import('../../components/dashboard/device-management/device-user'))
const TeamManagement = React.lazy(() => import('../../components/dashboard/team-management/index'))
const Locations = React.lazy(() => import('../../components/dashboard/system-actions/locations/view-locations'))
const InstanceLogger = React.lazy(() => import('../../components/dashboard/instance-logger'))
const Status = React.lazy(() => import('../../components/dashboard/system-actions/status/view-status'))
const Contests = React.lazy(() => import('../../components/dashboard/system-actions/contests/view-contests'))
const Licences = React.lazy(() => import('../../components/dashboard/system-actions/licences/view-drivers-licences'));
const VehicleBroadCasts = React.lazy(() => import('../../components/dashboard/system-actions/vehicles/vehicle-broadcast/view-vehicle-broadcasts'));
const PersonBroadCasts = React.lazy(() => import('../../components/dashboard/system-actions/persons/person-broadcast/view-person-broadcasts'));
const Cameras = React.lazy(() => import('../../components/dashboard/system-actions/cameras/view-cameras'));
const Ecommerce = React.lazy(() => import('../../components/dashboard/e-commerce/service-groups'));
const EcommerceVendor = React.lazy(() => import('../../components/dashboard/e-commerce/vendors'));
const EcommerceVendorService = React.lazy(() => import('../../components/dashboard/e-commerce/vendor-service'));
const EcommerceCreateTransaction = React.lazy(() => import('../../components/dashboard/e-commerce/transaction'));
const EcommerceTransaction = React.lazy(() => import('../../components/dashboard/e-commerce/transaction/view-transaction'));
const EcommerceTransactionRequests = React.lazy(() => import('../../components/dashboard/e-commerce/transaction/view-transaction-requests'));
const EcommerceAccount = React.lazy(() => import('../../components/dashboard/e-commerce/accounts/view-accounts'));
const AdminAction = React.lazy(() => import('../../components/dashboard/roles-and-permission/admin-action/view-admin-actions'));
const AdminActionGroup = React.lazy(() => import('../../components/dashboard/roles-and-permission/admin-action-group/view-action-group'));
const AdminRoleAction = React.lazy(() => import('../../components/dashboard/roles-and-permission/admin-role-action/view-action-role'));
const AdminRoles = React.lazy(() => import('../../components/dashboard/roles-and-permission/admin-roles/view-roles'));
const InvoiceReconciliations = React.lazy(() => import('../../components/dashboard/finance/invoice-reconciliations'));
const Banks = React.lazy(() => import('../../components/dashboard/finance/banks'));
const BankCashDeposits = React.lazy(() => import('../../components/dashboard/finance/bank-cash-deposits/view-bank-cash-deposits'));
const BankDepositBatches = React.lazy(() => import('../../components/dashboard/finance/bank-deposit-batches/view-bank-deposit-batches'));
const OutstandingPayments = React.lazy(() => import('../../components/dashboard/support/outstanding-payments/view-outstanding-payments'));
const WebsiteMessages = React.lazy(() => import('../../components/dashboard/support/website-messages'));
const WaiverRequests = React.lazy(() => import('../../components/dashboard/support/waiver-requests/view-waiver-requests'));
const Yards = React.lazy(() => import('../../components/dashboard/impounds/yards/view-yards'));
const YardLots = React.lazy(() => import('../../components/dashboard/impounds/yard-lots/view-yard-lots'));
const ImpoundRequest = React.lazy(() => import('../../components/dashboard/impounds/impound-request/view-impound-request'));
const Impounds = React.lazy(() => import('../../components/dashboard/impounds/impounds/view-impounds'));
const ImpoundRR = React.lazy(() => import('../../components/dashboard/impounds/impound-release-request/view-IRR'));
const ImpoundStatus = React.lazy(() => import('../../components/dashboard/impounds/impound-status/view-impound-status'));
const Page404 = React.lazy(()=>import('../404'))

export const route:Array<IUrl> = [
    {
        id : 1.1,
        PageRender : <Login />,
        path : url.LOGIN,
    },
    {
        id : 1,
        PageRender : <Vehicles />,
        path : url.VEHICLES,
        children: [{path: ':id', element: <Vehicles />}],
        view : AdminActions.vehicle_view
    },
    {
        id : 2,
        PageRender : <Organization />,
        path : url.ORGANIZATION,
        view : AdminActions.organizations_view_organization
    },
    {
        id : 3,
        PageRender : <Overview />,
        path : url.OVERVIEW,
        view : AdminActions.view_overview
    },
    {
        id : 4,
        PageRender : <CarBrands />,
        path : url.VEHICLE_CAR_BRANDS,
        view : AdminActions.vehicle_car_brand_view
    },
    {
        id : 6,
        PageRender : <VehicleOffenses />,
        path : url.VEHICLE_OFFENCES,
        children: [{path: ':id', element: <VehicleOffenses />}],
        view : AdminActions.vehicle_offence_view
    },
    {
        id : 7,
        PageRender : <VehicleFlag />,
        path : url.VEHICLE_FLAG,
        view : AdminActions.vehicle_flag_view
    },
    {
        id : 8,
        PageRender : <VehicleModel />,
        path : url.VEHICLE_MODEL,
        view : AdminActions.vehicle_model_view
    },
    {
        id : 84,
        PageRender : <VehicleDetailConflict />,
        path : url.VEHICLE_DETAIL_CONFLICT,
        children: [{path: ':id', element: <VehicleDetailConflict />}],
        view : AdminActions.vehicle_detail_conflict_view
    },
    {
        id : 9,
        PageRender : <Persons />,
        path : url.PERSONS,
        children: [{path: ':id', element: <Persons />}],
        view : AdminActions.person_view
    },
    {
        id : 10,
        PageRender : <PersonOffence />,
        path : url.PERSON_OFFENCES,
        children: [{path: ':id', element: <PersonOffence />}],
        view : AdminActions.person_offence_view
    },
    {
        id : 11,
        PageRender : <Finance />,
        path : url.FINANCE,
        view : AdminActions.finance_view
    },
    {
        id : 12,
        PageRender : <Offenses />,
        path : url.OFFENCE,
        view : AdminActions.offences_view_offence
    },
    {
        id : 13,
        PageRender : <OffenseCategory />,
        path : url.OFFENCE_CATEGORIES,
        view : AdminActions.offences_view_offences_category
    },
    {
        id : 14,
        PageRender : <UserManagement />,
        path : url.USER_MANAGEMENT,
        view : AdminActions.management_view_user_management
    },
    {
        id : 15,
        PageRender : <DeviceManagement />,
        path : url.DEVICE_MANAGEMENT,
        view : AdminActions.management_view_device_management
    },
    {
        id : 16,
        PageRender : <Locations />,
        path : url.LOCATION,
        view : AdminActions.location_view,
    },
    {
        id : 17,
        PageRender : <InstanceLogger />,
        path : url.INSTANCE_LOGGER,
        children: [{path: ':id', element: <InstanceLogger />}],
        view : AdminActions.instance_log_view_list
    },
    {
        id : 18,
        PageRender : <Status />,
        path : url.STATUS,
        view : AdminActions.status_view_status
    },
    {
        id : 19,
        PageRender : <Licences />,
        path : url.LICENSE,
        view : AdminActions.licence_view
    },
    {
        id : 20,
        PageRender : <VehicleBroadCasts />,
        path : url.VEHICLE_BROADCASTS,
        view : AdminActions.vehicle_broadcast_view
    },
    {
        id : 21,
        PageRender : <PersonBroadCasts />,
        path : url.PERSON_BROADCASTS,
        view : AdminActions.person_broadcast_view
    },
    {
        id : 22,
        PageRender : <Cameras />,
        path : url.CAMERAS,
        view : AdminActions.camera_view
    },
    {
        id : 23,
        PageRender : <Ecommerce />,
        path : url.ECOMMERCE,
        view : AdminActions.ecommerce_view
    },
    {
        id : 24,
        PageRender : <EcommerceVendor />,
        path : url.ECOMMERCE_VENDORS,
        children: [{path: ':id', element: <EcommerceVendor />}],
        view : AdminActions.ecommerce_view_vendors
    },
    {
        id : 25,
        PageRender : <EcommerceVendorService />,
        path : url.ECOMMERCE_VENDOR_SERVICES,
        children: [{path: ':id', element: <EcommerceVendorService />}],
        view : AdminActions.ecommerce_view_vendor_services
    },
    {
        id : 26,
        PageRender : <EcommerceCreateTransaction />,
        path : url.ECOMMERCE_CREATE_TRANSACTION,
        view : AdminActions.ecommerce_create_transaction
    },
    {
        id : 27,
        PageRender : <EcommerceTransaction />,
        path : url.ECOMMERCE_TRANSACTION,
        children: [{path: ':id', element: <EcommerceTransaction />}],
        view : AdminActions.ecommerce_view_transactions
    },
    {
        id : 28,
        PageRender : <DeviceUser />,
        path : url.DEVICE_USER,
        view : AdminActions.management_view_device_user
    },
    {
        id : 29,
        PageRender : <EcommerceAccount />,
        path : url.ECOMMERCE_ACCOUNTS,
        children: [{path: ':id', element: <EcommerceAccount />}],
        view : AdminActions.ecommerce_view_accounts
    },
    {
        id : 30,
        PageRender : <VehicleCrash />,
        path : url.VEHICLE_CRASH,
        view : AdminActions.vehicle_crash_view
    },
    {
        id : 31,
        PageRender : <EcommerceTransactionRequests />,
        path : url.ECOMMERCE_TRANSACTION_REQUESTS,
        children: [{path: ':id', element: <EcommerceTransactionRequests />}],
        view : AdminActions.ecommerce_view_transaction_requests
    },
    {
        id : 32,
        PageRender : <VehicleOffensesPayment />,
        path : url.VEHICLE_OFFENCE_PAYMENT,
        children: [{path: ':id', element: <VehicleOffensesPayment />}],
        view : AdminActions.vehicle_offence_payment_view
    },
    {   id : 33,
        PageRender : <PersonOffencePayment />,
        path : url.PERSON_OFFENCE_PAYMENT,
        children: [{path: ':id', element: <PersonOffencePayment />}],
        view : AdminActions.person_offence_payment_view
    },
    {   id : 34,
        PageRender : <AdminAction />,
        path : url.ADMIN_ACTION,
        view : AdminActions.role_view_actions
    },
    {   id : 35,
        PageRender : <AdminActionGroup />,
        path : url.ADMIN_ACTION_GROUP,
        view :AdminActions.role_view_action_group
    },
    {   id : 36,
        PageRender : <AdminRoleAction />,
        path : url.ADMIN_ROLE_ACTIONS,
        view : AdminActions.roles_view_role_action
    },
    {   id : 37,
        PageRender : <Contests />,
        path : url.CONTESTS,
        children: [{path: ':id', element: <Contests />}],
        view : AdminActions.contest_view
    },
    {   id : 38,
        PageRender : <AdminProfile />,
        path : url.ADMINPROFILE,
        view : AdminActions.view_profile
    },
    {   id : 39,
        PageRender : <Page404 />,
        path : url.PAGE404,
        view : AdminActions.management_view_users
    },
    {
        id : 40,
        PageRender : <TeamManagement />,
        path : url.TEAM_MANAGEMENT,
        view : AdminActions.management_view_team_management
    },
    {
        id : 41,
        PageRender : <CashTransactions />,
        path : url.CASH_TRANSACTIONS,
        children: [{path: ':id', element: <CashTransactions />}],
        view : AdminActions.finance_view_cash_transactions
    },
    {
        id : 42,
        PageRender : <InvoiceReconciliations />,
        path : url.INVOICE_RECONCILIATIONS,
        children: [{path: ':id', element: <InvoiceReconciliations />}],
        view : AdminActions.finance_view_cash_transactions
    },
    {
        id : 43,
        PageRender : <AdminRoles />,
        path : url.ADMIN_ROLES,
        view : AdminActions.role_create_action
    },
    {
        id : 44,
        PageRender : <Banks />,
        path : url.BANKS,
        view : AdminActions.finance_view_bank
    },
    {
        id : 45,
        PageRender : <BankCashDeposits />,
        path : url.BANK_CASH_DEPOSITS,
        children: [{path: ':id', element: <BankCashDeposits />}],
        view : AdminActions.finance_view_bank_cash_deposits
    },
    {
        id : 46,
        PageRender : <BankDepositBatches />,
        path : url.BANK_DEPOSIT_BATCHES,
        children: [{path: ':id', element: <BankDepositBatches />}],
        view : AdminActions.finance_view_bank_deposit_batches
    },
    {
        id : 47,
        PageRender : <OutstandingPayments />,
        path : url.OUTSTANDING_PAYMENT,
        children: [{path: ':id', element: <OutstandingPayments />}],
        view : AdminActions.outstanding_payment_view
    },
    {
        id : 48,
        PageRender : <VehicleProfiles />,
        path : url.VEHICLE_PROFILES,
        children: [{path: ':id', element: <VehicleProfiles />}],
        view : AdminActions.vehicle_view_profiles
    },
    {
        id : 49,
        PageRender : <VehicleOffencePaymentWOInvoice />,
        path : url.VOP_WO_INVOICE,
        children: [{path: ':id', element: <VehicleOffencePaymentWOInvoice />}],
        view : AdminActions.finance_view_vehicle_offence_payments_wo_invoice
    },
    {
        id : 50,
        PageRender : <WebsiteMessages />,
        path : url.WEBSITE_MESSAGES,
        children: [{path: ':id', element: <WebsiteMessages />}],
        view : AdminActions.support_view_website_messages
    },
    {
        id : 51,
        PageRender : <WaiverRequests />,
        path : url.WAIVER_REQUESTS,
        children: [{path: ':id', element: <WaiverRequests />}],
        view : AdminActions.support_view_waiver_requests
    },
    {
        id : 52,
        PageRender : <Yards />,
        path : url.YARD,
        children: [{path: ':id', element: <Yards />}],
        view : AdminActions.impounds_view_yards
    },
    {
        id : 53,
        PageRender : <YardLots />,
        path : url.YARD_LOTS,
        children: [{path: ':id', element: <YardLots />}],
        view : AdminActions.impounds_view_yard_lots
    },
    {
        id : 54,
        PageRender : <ImpoundRequest />,
        path : url.IMPOUND_REQUESTS ,
        children: [{path: ':id', element: <ImpoundRequest />}],
        view : AdminActions.impounds_view_impound_requests
    },
    {
        id : 55,
        PageRender : <Impounds />,
        path : url.IMPOUNDS,
        children: [{path: ':id', element: <Impounds />}],
        view : AdminActions.impounds_view_impounds
    },
    {
        id : 56,
        PageRender : <ImpoundRR />,
        path : url.IMPOUND_RELEASE_REQUESTS,
        children: [{path: ':id', element: <ImpoundRR />}],
        view : AdminActions.impounds_view_impound_release_requests
    },
    {
        id : 57,
        PageRender : <ImpoundStatus />,
        path : url.IMPOUND_STATUS,
        view : AdminActions.impounds_view_status
    },
];