import React, { FC, useEffect } from 'react';
import { connect } from "react-redux";
import { IAppState } from "./interfaces/IAppState";
import { IAdmin } from "./interfaces/IAdmin";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { route } from './utils/route';
import { logOutAdmin, getAdminRoles } from "./store/actions";
import SessionTimeout from './utils/auto-logout';
import { decodedToken } from './utils/token';
import {url} from 'enums/Route';

interface IStateProps {
	admin: IAdmin;
}

interface IDispatchProps {
    logOutAdmin: () => void;
    getAdminRoles: ( id: string ) => void;
};

interface IProps extends IStateProps, IDispatchProps {}

const RouteList:FC<IProps> = (props) => {
    const { admin, logOutAdmin, getAdminRoles } = props;
    const { authenticated = false, adminRoles } = admin || {};
    const Wow = SessionTimeout( admin, logOutAdmin );
    const navigate = useNavigate();

    useEffect(() => {
        adminRoles!?.length === 0 && getAdminRoles(decodedToken.role)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!authenticated){
            navigate(url.LOGIN);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authenticated, navigate])

    return(
        <>
            <Routes>
                {route!?.map( (item) => {
                    return(
                        <Route key={ item.id } path={ item.path } element={ item.PageRender }>
                            {item.children &&
                                item.children!?.map((ch, idx) => {
                                    return(
                                        <Route path={ch!?.path} element={ch!?.element} key={idx} />
                                    )
                                })
                            }
                        </Route>
                    ) 
                })}
            </Routes>
            <div>{Wow}</div>
        </>
    );
};

const mapStateToProps = (state: IAppState): IStateProps => ({
	admin: state.admin,
});

const mapDispatchToProps: IDispatchProps = {
    logOutAdmin,
    getAdminRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteList);

// {
//     <Routes>
//         {route.map( ( item ) => {
//             return( 
//                 checkRole(item.view!, admin.adminRoles) &&
//                     <Route path={ item.path } key={ item.id } 
//                         element={authenticated ? 
//                             item.PageRender
//                             :
//                             <Navigate to={{ pathname: pageurl.TMADMIN }} />
//                         }
//                     />
                            
//             ) 
//         })}
//     </Routes> 
//     // <Navigate to={{pathname: '/'}} />
// }