import { ActionEnums } from '../../../enums/ActionEnums'
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_DEVICES, SET_ALL_DEVICE_USERS, SET_DEVICES_BY_SEARCH, } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllDevices = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/devise`,
		actionEnum: ActionEnums.GET_ALL_DEVICES,
		disPatch: [{type: SET_ALL_DEVICES, payload: pageNumber !== undefined && pageSize !== undefined ? 'devices' : '' }], pageNumber, pageSize,
	});

export const getDevicesBySearch = (query?: string, pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/devise`,
		actionEnum: ActionEnums.GET_DEVICES_BY_SEARCH,
		disPatch: [{type: SET_DEVICES_BY_SEARCH, payload: 'devises' }], pageNumber, pageSize, query
	});

export const clearDevicesBySearch = ( ) => ( dispatch : Function ) =>{
	dispatch(dispatch( { type : SET_DEVICES_BY_SEARCH, payload: [] } ))
};

export const getAllDeviceUsers = () => 
	getRequest({
		url: `${etraffica_baseurl}/deviseuser`,
		actionEnum: ActionEnums.GET_ALL_DEVICE_USERS,
		disPatch: [{type: SET_ALL_DEVICE_USERS, payload: '' }]
	});

export const addDevice = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/devise/update` : `${etraffica_baseurl}/devise`,
		actionEnum: update? ActionEnums.UPDATE_DEVICE : ActionEnums.ADD_DEVICE,
		data, update
	});

export const assignDevice = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/deviseuser`, actionEnum: ActionEnums.ASSIGN_DEVICE, data
	});