import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_SOT_INSURANCE_RESPONSE, SET_SOT_PAAR_RESPONSE } from '../types';
import { postRequest } from 'store/services';

export const sendSMSNotification = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/notification/sms`, actionEnum: ActionEnums.SEND_NOTIFICATION, data
	});

export const sendWhatsappNotification = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/notification/whatsapp`, actionEnum: ActionEnums.SEND_NOTIFICATION, data
	});

export const sendCallNotification = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/notification/call`, actionEnum: ActionEnums.SEND_NOTIFICATION, data
	});

export const bookVehicle = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/vehicleoffense`, actionEnum: ActionEnums.BOOK_VEHICLE, data
	});

export const issueWarning = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/warning`, actionEnum: ActionEnums.ISSUE_WARNING, data
	});

export const SOTInsuranceCheck = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/sotmanager/insurance`, actionEnum: ActionEnums.SOT_INSURANCE_CHECK,
		disPatch:[{type: SET_SOT_INSURANCE_RESPONSE, payload: ''}], data
	});

export const clearSOTInsuranceCheck = () => (dispatch : Function) => {
	dispatch({type : SET_SOT_INSURANCE_RESPONSE, payload : {} })
};

export const SOTPaarCheck = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/sotmanager/paar`, actionEnum: ActionEnums.SOT_PAAR_CHECK,
		disPatch:[{type: SET_SOT_PAAR_RESPONSE, payload: 'data'}] , data
	});

export const clearSOTPAARCheck = () => (dispatch : Function) => {
	dispatch({ type : SET_SOT_PAAR_RESPONSE, payload :{} })
};