import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IMenuData, ISubMenuData } from '../../interfaces/IMenu';

interface INavC {
    item: IMenuData;
}
export const NavComponent:FC<INavC> = ( props ) => {

    const { item } = props

    return(
        <NavLink
            to={ item.url }
            className={isActive => 
                !isActive ? "menu-item-container" : "main-active"
            }
            // isActive={
            //     (match : any, location : any): boolean => {  
            //         let isUrl: boolean = false;
            //         let url = location && location.pathname
            //         if(url === item.url){
            //             isUrl = true;
            //         }
            //         return isUrl;
            //     }                      
            // }
        >
            { props.children }               
        </NavLink>
    )
}

interface INavParent {
    item: IMenuData;
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    isClicked: boolean;
}
export const NavIsParentComponent:FC<INavParent> = ( props ) => {

    const { item, setIsClicked, isClicked } = props

    return(
        <NavLink
        to={ item.url }
        onClick={(e)=>{e.preventDefault(); setIsClicked( !isClicked ); }}
        className={isActive => 
            !isActive ? "menu-item-container" : "main-active"
        }
            // isActive={
            //     (match : any, location : any): boolean => {  
            //         let isUrl: boolean = false;
            //         let url = location && location.pathname
            //         if( Array.isArray( item.suburls ) ){
            //             for(let i = 0; i < item.suburls.length; i++){
            //                 if( url === item.suburls[i] || url.includes( item.suburls[i] ) ){
            //                     isUrl = true;
            //                 }
            //             }
            //         }
            //         return isUrl;
            //     }                      
            // }
    >
        { props.children }               
        </NavLink>
    )
}

interface ISubMenu {
    subItem: ISubMenuData;
    isLast: boolean;
}
export const NavSubComponent:FC<ISubMenu> = ( props ) => {

    const { subItem, isLast } = props

    return(
        <NavLink
        to={ subItem.url }
        className={isActive => 
            !isActive ? `menu-subitem ${ isLast && "last"}` : "sub-active"
        }
        // isActive={
        //     (match : any, location : any): boolean => {  
        //         let isUrl: boolean = false;
        //         let url = location && location.pathname
        //         if(url === item.url){
        //             isUrl = true;
        //         }
        //         return isUrl;
        //     }                      
        // }
    >
        { props.children }               
        </NavLink>
    )
}