import { etraffica_baseurl } from "../../utils/constants";
import { ActionEnums } from '../../enums/ActionEnums';
import { getRequest, postRequest } from "store/services";
import {  SET_SERVICE_GROUPS, SET_VENDORS, SET_VENDOR_SERVICES, SET_VENDOR_SERVICES_BY_ID,
	SET_ESERVICE_GROUPTAX_BY_ID, SET_ESERVICE_ACCOUNTS, SET_ESERVICE_ACCOUNT_TYPES, SET_TRANSACTIONS,
	SET_TRANSACTIONS_BY_SEARCH_PARAMS, CLEAR_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS, CLEAR_TRANSACTION_BY_SEARCH_PARAMS,
	SET_TRANSACTION_REQUESTS, SET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS,
} from "./types";

export const getServiceGroups = () => 
	getRequest({
		url: `${etraffica_baseurl}/eservicegroup`, actionEnum: ActionEnums.GET_SERVICE_GROUPS,
		disPatch: [{type: SET_SERVICE_GROUPS, payload: 'serviceGroups' }]
	});

export const createServiceGroup = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/eservicegroup`, actionEnum: ActionEnums.CREATE_SERVICE_GROUP, data,
	});

export const getVendors = () => 
	getRequest({
		url: `${etraffica_baseurl}/evendor`, actionEnum: ActionEnums.GET_VENDORS,
		disPatch: [{type: SET_VENDORS, payload: 'vendor' }]
	});

export const createVendor = ( data : object, update?: boolean ) => 
	postRequest({
		url: update?  `${etraffica_baseurl}/evendor/update` :  `${etraffica_baseurl}/evendor`,
		actionEnum: update ? ActionEnums.UPDATE_VENDOR : ActionEnums.CREATE_VENDOR, data, update
	});

export const getVendorServices = () => 
	getRequest({
		url: `${etraffica_baseurl}/evendorservice`, actionEnum: ActionEnums.GET_VENDOR_SERVICE,
		disPatch: [{type: SET_VENDOR_SERVICES, payload: 'vendorServices' }]
	});

export const getVendorServicesById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/evendorservice/service`, actionEnum: ActionEnums.GET_VENDOR_SERVICE_BY_ID, id,
		disPatch: [{type: SET_VENDOR_SERVICES_BY_ID, payload: 'vendorServices' }]
	});

export const createVendorService = ( data : object, update?: boolean ) => 
	postRequest({
		url: update?  `${etraffica_baseurl}/evendorservice/update` : `${etraffica_baseurl}/evendorservice`,
		actionEnum: update ? ActionEnums.UPDATE_VENDOR_SERVICE : ActionEnums.CREATE_VENDOR_SERVICE, data, update
	});

export const createTransaction = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/etransaction`, actionEnum: ActionEnums.CREATE_TRANSACTION, data,
	});

export const getEServiceGroupTaxById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/eservicegrouptax/group`, actionEnum: ActionEnums.GET_E_SERVICE_GROUP_TAX_BY_ID, id,
		disPatch: [{type: SET_ESERVICE_GROUPTAX_BY_ID, payload: '' }]
	});

export const getAccounts = () => 
	getRequest({
		url: `${etraffica_baseurl}/eaccount`, actionEnum: ActionEnums.GET_ACCOUNTS,
		disPatch: [{type: SET_ESERVICE_ACCOUNTS, payload: 'data' }]
	});

export const createAccount = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/eaccount/update` : `${etraffica_baseurl}/eaccount`,
		actionEnum: update ? ActionEnums.UPDATE_ACCOUNT: ActionEnums.CREATE_ACCOUNT, data, update
	});

export const getAccountTypes = () => 
	getRequest({
		url: `${etraffica_baseurl}/eaccounttype`, actionEnum: ActionEnums.GET_ACCOUNT_TYPES,
		disPatch: [{type: SET_ESERVICE_ACCOUNT_TYPES, payload: 'data' }]
	});

export const linkDeviceToAccount = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/2edeviseaccount`, actionEnum: ActionEnums.LINK_DEVICE_TO_ACCOUNT, data,
	});

export const getTransactions = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/etransaction`, actionEnum: ActionEnums.GET_TRANSACTIONS,
		disPatch: [{type: SET_TRANSACTIONS, payload: 'data' }], pageNumber, pageSize,
	});

export const getTransactionsBySearchParams = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/etransaction`,
		actionEnum: ActionEnums.GET_TRANSACTIONS_BY_SEARCH_PARAMS, pageNumber, pageSize, query,
		disPatch: [{type: SET_TRANSACTIONS_BY_SEARCH_PARAMS, payload: 'data' }]
	});

export const clearTransactionRequestBySearchParms = ( ) => ( dispatch : Function ) => {
	dispatch( {type : CLEAR_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS } )
};

export const clearTransactionBySearchParms = ( ) => ( dispatch : Function ) => {
	dispatch( {type : CLEAR_TRANSACTION_BY_SEARCH_PARAMS } )
};

export const getTransactionRequests = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/etransactionrequest`, actionEnum: ActionEnums.GET_TRANSACTION_REQUESTS,
		disPatch: [{type: SET_TRANSACTION_REQUESTS, payload: 'data' }], pageNumber, pageSize,
	});

export const getTransactionRequestsBySearchParams = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/etransactionrequest`,
		actionEnum: ActionEnums.GET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS, pageNumber, pageSize, query,
		disPatch: [{type: SET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS, payload: 'data' }]
	});