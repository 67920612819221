import { SET_ALL_ADMIN_ACTION_GROUPS, SET_SINGLE_ADMIN_ACTION_GROUP, SET_ALL_ADMIN_ACTIONS, SET_SINGLE_ADMIN_ACTION, SET_ALL_ADMIN_ROLE_ACTIONS, SET_ADMIN_ROLE_ACTIONS_BY_SEARCH, SET_ROLES, SET_USER_ROLES
} from './types'
import { ActionEnums } from '../../enums/ActionEnums'
import { etraffica_baseurl } from "../../utils/constants";
import { getRequest, postRequest } from 'store/services';

export const getAllAdminActionGroups = () => 
	getRequest({
		url: `${etraffica_baseurl}/actiongroup`, actionEnum: ActionEnums.GET_ALL_ACTION_GROUPS,
		disPatch: [{type: SET_ALL_ADMIN_ACTION_GROUPS, payload: 'result' }]
	});

export const addNewAdminActionGroup = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/actiongroup`, actionEnum: ActionEnums.ADD_NEW_ACTION_GROUP, data
	});

export const getSingleAdminActionGroup = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/actiongroup`, actionEnum: ActionEnums.GET_SINGLE_ACTION_GROUPS, id,
		disPatch: [{type: SET_SINGLE_ADMIN_ACTION_GROUP, payload: 'result' }]
	});

export const getAllAdminActions = () => 
	getRequest({
		url: `${etraffica_baseurl}/action`, actionEnum: ActionEnums.GET_ALL_ACTIONS,
		disPatch: [{type: SET_ALL_ADMIN_ACTIONS, payload: 'result' }]
	});

export const addNewAdminAction = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/action`, actionEnum: ActionEnums.ADD_NEW_ACTION, data
	});

export const getSingleAdminAction = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/action`, actionEnum: ActionEnums.GET_SINGLE_ACTION, id,
		disPatch: [{type: SET_SINGLE_ADMIN_ACTION, payload: 'result' }]
	});

export const getAllAdminRoleActions = () => 
	getRequest({
		url: `${etraffica_baseurl}/roleaction`, actionEnum: ActionEnums.GET_ALL_ROLE_ACTIONS,
		disPatch: [{type: SET_ALL_ADMIN_ROLE_ACTIONS, payload: 'result' }]
	});

export const getAdminRoleActionsBySearch = (query?: string, pageNumber?: number, pageSize? : number) => 
	getRequest({
		url: `${etraffica_baseurl}/roleaction`,
		actionEnum: ActionEnums.GET_ALL_ROLE_ACTIONS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_ADMIN_ROLE_ACTIONS_BY_SEARCH, payload: 'result' }]
	});

export const clearAdminRoleActionsBySearch = () => (dispatch:Function) => {
	dispatch({SET_ADMIN_ROLE_ACTIONS_BY_SEARCH, payload:[]})
};

export const getRoles = () => 
	getRequest({
		url: `${etraffica_baseurl}/role`, actionEnum: ActionEnums.GET_ROLES,
		disPatch: [{type: SET_ROLES, payload: 'roles' }]
	});

export const createRole = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/role`, actionEnum: ActionEnums.CREATE_ROLE, data
	});

export const assignAdminRoleAction = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/roleaction`, actionEnum: ActionEnums.ASSIGN_ROLE_ACTION, data
	});

export const unassignAdminRoleAction = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/roleaction/delete`, actionEnum: ActionEnums.UNASSIGN_ROLE_ACTION, data
	});

export const getUserRoles = (id:string) => 
	getRequest({
		url: `${etraffica_baseurl}/roleaction/role`, actionEnum: ActionEnums.GET_USER_ROLES, id: parseInt(id),
		disPatch: [{type: SET_USER_ROLES, payload: '' }]
	});

export const clearUserRoles = () => (dispatch : Function) => {
	dispatch({ type: SET_USER_ROLES, payload: [] })
};