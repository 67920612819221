
export enum url {
    LOGIN = "/",
    P404 = '/404',
    OVERVIEW = "/admin-dashboard",
    ADMINPROFILE = "/admin-dashboard/admin-profile",
    VEHICLES = "/admin-dashboard/vehicles",
    VEHICLE_OFFENCES = "/admin-dashboard/vehicle-offences",
    VEHICLE_OFFENCE_PAYMENT = '/admin-dashboard/vehicle-offences-payment',
    LICENSE = "/admin-dashboard/license",
    VEHICLE_FLAG = "/admin-dashboard/vehicle-flags",
    VEHICLE_CAR_BRANDS = "/admin-dashboard/vehicle-brands",
    VEHICLE_MODEL = "/admin-dashboard/vehicle-models",
    VEHICLE_BROADCASTS = "/admin-dashboard/vehicle-broadcasts",
    VEHICLE_CRASH = "/admin-dashboard/vehicle-crashes",
    VEHICLE_DETAIL_CONFLICT = "/admin-dashboard/vehicle-detail-conflict",
    PERSONS = "/admin-dashboard/persons",
    PERSON_OFFENCES = "/admin-dashboard/person-offences",
    PERSON_OFFENCE_PAYMENT = "/admin-dashboard/person-offence-payment",
    PERSON_BROADCASTS = "/admin-dashboard/person-broadcasts",
    FINANCE = "/admin-dashboard/finances",
    OFFENCE = "/admin-dashboard/offences",
    OFFENCE_CATEGORIES = "/admin-dashboard/offence-categories",
    USER_MANAGEMENT = "/admin-dashboard/user-management",
    DEVICE_MANAGEMENT = "/admin-dashboard/device-management",
    DEVICE_USER = '/admin-dashboard/device-user',
    TEAM_MANAGEMENT = '/admin-dashboard/team-management',
    ORGANIZATION = "/admin-dashboard/organizations",
    LOCATION = "/admin-dashboard/locations",
    INSTANCE_LOGGER = "/admin-dashboard/instance-logger",
    STATUS = "/admin-dashboard/status",
    CAMERAS = "/admin-dashboard/cameras",
    CONTESTS = "/admin-dashboard/contests",
    ECOMMERCE = "/admin-dashboard/e-commerce/service-groups",
    ECOMMERCE_VENDORS = "/admin-dashboard/e-commerce/vendors",
    ECOMMERCE_VENDOR_SERVICES = "/admin-dashboard/e-commerce/service-groups/vendor-services",
    ECOMMERCE_CREATE_TRANSACTION = "/admin-dashboard/e-commerce/service-groups/vendor-services/create-transaction",
    ECOMMERCE_ACCOUNTS = "/admin-dashboard/e-commerce/accounts",
    ECOMMERCE_TRANSACTION = "/admin-dashboard/e-commerce/transaction",
    ECOMMERCE_TRANSACTION_REQUESTS = "/admin-dashboard/e-commerce/transaction-requests",
    ROLES = '/admin-dashboard/roles-and-permissions',
    ADMIN_ACTION = '/admin-dashboard/roles-and-permissions/action',
    ADMIN_ACTION_GROUP = '/admin-dashboard/roles-and-permissions/action-group',
    ADMIN_ROLE_ACTIONS = '/admin-dashboard/roles-and-permissions/role-action',
    ADMIN_ROLES = '/admin-dashboard/roles-and-permissions/roles',
    CASH_TRANSACTIONS = '/admin-dashboard/finance/cash-transactions',
    INVOICE_RECONCILIATIONS = '/admin-dashboard/finance/invoice-reconciliations',
    BANKS = '/admin-dashboard/finance/banks',
    BANK_CASH_DEPOSITS = '/admin-dashboard/finance/bank-cash-deposits',
    BANK_DEPOSIT_BATCHES = '/admin-dashboard/finance/bank-deposit-batches',
    SUPPORT = '/admin-dashboard/support',
    OUTSTANDING_PAYMENT = '/admin-dashboard/support/outstanding-payment',
    WEBSITE_MESSAGES = '/admin-dashboard/support/website-messages',
    WAIVER_REQUESTS = '/admin-dashboard/support/waiver-requests',
    IMPOUNDS = '/admin-dashboard/impound',
    IMPOUND_REQUESTS = '/admin-dashboard/impounds/impound-requests',
    IMPOUND_RELEASE_REQUESTS = '/admin-dashboard/impounds/impound-release-requests',
    IMPOUND_STATUS = '/admin-dashboard/impounds/impound-status',
    YARD = '/admin-dashboard/impounds/yards',
    YARD_LOTS = '/admin-dashboard/impounds/yardlots',
    VEHICLE_PROFILES = "/admin-dashboard/vehicle-profiles",
    VOP_WO_INVOICE = "/admin-dashboard/finance/vehicle-offence-payments-without-invoice",
    PAGE404 = '/page404'
}