import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import violatorReducer from "./violatorReducer";
import instanceLoggerReducer from './instanceLoggerReducer';
import eCommerceReducer from './eCommerceReducer';
import httpReducer from './httpReducer'
import adminRoleReducer from './adminRoleReducer'



const rootReducer = combineReducers({
    admin: adminReducer,
    violator: violatorReducer,
    vehicleInstances: instanceLoggerReducer,
    eCommerce: eCommerceReducer,
    http: httpReducer,
    adminRole : adminRoleReducer
});

export default rootReducer;