import React from 'react'
import Navbar from '../../components/navigations'
import { pageurl } from '../constants'
import './general.scss'
import { useLocation } from 'react-router-dom';
import { url } from '../../enums/Route';
// import { decodedToken } from '../token';

const DashboardWrapper = (props: any) => {
    const location = useLocation();

    return(
        <>
        { (location.pathname !== pageurl.TMLOGIN && location.pathname !== url.PAGE404) ?
            
            <div className='dashboard-wrapper'>
                <Navbar/>
                <div className='content'>
                    { props.children }
                </div>
            </div>
        :      
            props.children
        }
        </>

    )
} 

export default DashboardWrapper;