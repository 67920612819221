import { ActionEnums } from '../../../enums/ActionEnums';
import { GET_CITATION_INFORMATION, SET_INSURANCE_INFO, SET_RESPONSE_EMAILS, SET_WAIVER_REQUESTS, SET_WAIVER_REQUESTS_BY_SEARCH, SET_WAIVER_REQUEST_STATUSES, SET_WEBSITE_MESSAGES, SET_WAIVER_DOCUMENTS, SET_WAIVER_COMMENTS, SET_WAIVER_REQUEST_DATA, SET_WAIVER_REQUESTS_BY_ID } from '../types';
import { etraffica_baseurl } from '../../../utils/constants';
import { getRequest, postRequest } from 'store/services';
const genericSupportUrl = 'https://genericapis.myapiservices.net/api/v1';

export const getWebsiteMessages = () => 
	getRequest({
		url: `${genericSupportUrl}/ContactUs/GetFeedBack`,
		actionEnum: ActionEnums.GET_WEBSITE_MESSAGES,
		disPatch: [{type: SET_WEBSITE_MESSAGES, payload: 'contactUsFeecbaks' }]
	});

export const deleteWebsiteMessage = (id: number) => 
	getRequest({
		url: `${genericSupportUrl}/ContactUs/DeleteFeedBack`, actionEnum: ActionEnums.DELETE_WEBSITE_MESSAGE
	});

export const getCitationInformation = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/documentgenerator/citation`,
		actionEnum: ActionEnums.GET_CITATION_INFORMATION, data,
		disPatch:[{type: GET_CITATION_INFORMATION, payload:'' }]
	});

export const getResponseEmails = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${genericSupportUrl}/Emails/GetAllSentEMails`, noPaged: true,
		actionEnum: ActionEnums.GET_RESPONSE_EMAILS, pageNumber, pageSize,
		disPatch: [{type: SET_RESPONSE_EMAILS, payload: 'emails' }]
	});

export const sendResponseEmail = ( data : object ) => 
	postRequest({
		url: `${genericSupportUrl}/Emails/SendEmail`,
		actionEnum: ActionEnums.RESPOND_TO_EMAIL, data,
	});

export const getInsuranceInfo = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/sotmanager/insurance/check`,
		actionEnum: ActionEnums.GET_INSURANCE_INFORMATION, data,
		disPatch:[{type: SET_INSURANCE_INFO, payload: 'data'}]
	});

export const clearInsuranceInfo = () => (dispatch: Function) => {
	dispatch({ type: SET_INSURANCE_INFO, payload: {} });
};

//waivers
export const getWaiverRequests = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequest`,
		actionEnum: ActionEnums.GET_WAIVER_REQUESTS, pageNumber, pageSize,
		disPatch: [{type: SET_WAIVER_REQUESTS, payload: 'data' }]
	});

export const getWaiverRequestsBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequest`,
		actionEnum: ActionEnums.GET_WAIVER_REQUESTS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_WAIVER_REQUESTS_BY_SEARCH, payload: 'data' }]
	});

export const getWaiverRequestById = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequest`,
		actionEnum: ActionEnums.GET_WAIVER_REQUEST_BY_ID, id,
		disPatch: [{type: SET_WAIVER_REQUESTS_BY_ID, payload: 'data' }]
	});

export const clearWaiverRequestsBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_WAIVER_REQUESTS_BY_SEARCH, payload: [] });
};

export const clearWaiverRequestById = () => (dispatch: Function) => {
	dispatch({ type: SET_WAIVER_REQUESTS_BY_ID, payload: {} });
};

export const createWaiver = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/waiverrequest/update` : `${etraffica_baseurl}/waiverrequest`,
		actionEnum: update ? ActionEnums.UPDATE_WAIVER : ActionEnums.CREATE_WAIVER, data, update,
		disPatch:[{type: SET_WAIVER_REQUEST_DATA, payload: 'data'}]
	});

export const deleteWaiverRequest = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequest/delete`,
		actionEnum: ActionEnums.DELETE_WAIVER, id
	});

export const getWaiverRequestStatuses = () => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequeststatus`,
		actionEnum: ActionEnums.GET_WAIVER_REQUEST_STATUSES,
		disPatch: [{type: SET_WAIVER_REQUEST_STATUSES, payload: 'data' }]
	});

export const createWaiverRequestStatus = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/waiverrequeststatus/update` : `${etraffica_baseurl}/waiverrequeststatus`,
		actionEnum: update ? ActionEnums.UPDATE_WAIVER_REQUEST_STATUS : ActionEnums.CREATE_WAIVER_REQUEST_STATUS,
		data, update,
	});

export const uploadWaiverDocument = ( data : FormData ) => 
	postRequest({
		url: `${etraffica_baseurl}/waiverrequestdocument`,
		actionEnum: ActionEnums.UPLOAD_WAIVER_DOCS, data,
	});

export const getWaiverDocuments = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequestdocument/request`,
		actionEnum: ActionEnums.GET_WAIVER_DOCUMENTS, id,
		disPatch:[{type: SET_WAIVER_DOCUMENTS, payload: 'data'}]
	});

export const deleteWaiverDocument = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequestdocument/delete`,
		actionEnum: ActionEnums.DELETE_WAIVER_DOCUMENTS, id
	});

export const approveWaiver = ( data : object, disapprove?: boolean ) => 
	postRequest({
		url: disapprove ? `${etraffica_baseurl}/waiverrequest/disapprove` : `${etraffica_baseurl}/waiverrequest/approve`,
		actionEnum: disapprove ? ActionEnums.UPDATE_WAIVER : ActionEnums.CREATE_WAIVER, data, update: disapprove
	});

//comment
export const addWaiverComment = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/waiverrequestcomment`,
		actionEnum: ActionEnums.ADD_WAIVER_REQUEST_COMMENTS, data,
	});

export const getWaiverComments = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequestcomment/request`,
		actionEnum: ActionEnums.GET_WAIVER_COMMENTS, id,
		disPatch:[{type: SET_WAIVER_COMMENTS, payload: 'data'}]
	});

export const deleteWaiverComment = ( id: number ) => 
	getRequest({
		url: `${etraffica_baseurl}/waiverrequestcomment/delete`,
		actionEnum: ActionEnums.DELETE_WAIVER_COMMENT, id
	});