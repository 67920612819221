import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS, SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH, SET_ALL_VEHICLE_OFFENSES, SET_VEHICLE_OFFENCE_BY_ID, SET_VEHICLE_OFFENCE_BY_SEARCH, SET_VEHICLE_OFFENCE_PAYMENT_BY_ID, SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH, SET_VEHICLE_OFFENCE_THUMBNAILS, SET_VEHICLE_OFFENSE_PAYMENT, SET_VEHICLE_OFFENSE_PAYMENT_STATUSES,  } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllVehicleOffences = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleoffense`,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCES,
		disPatch: [{type: SET_ALL_VEHICLE_OFFENSES, payload: 'vehicleOffense' }], pageNumber, pageSize,
	});

export const getVehicleOffenceBySearch = (query?: string, pageNumber?: number, pageSize? : number, isThumbNails?:boolean ) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleoffense`,
		actionEnum: ActionEnums.GET_VEHICLE_OFFENCE_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: isThumbNails ? SET_VEHICLE_OFFENCE_THUMBNAILS : SET_VEHICLE_OFFENCE_BY_SEARCH, payload: 'data' }]
	});

export const getVehicleOffenceById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleoffense`,
		actionEnum: ActionEnums.GET_VEHICLE_OFFENCE_BY_ID, id,
		disPatch: [{type: SET_VEHICLE_OFFENCE_BY_ID, payload: 'vehicleOffense' }]
	});

export const clearVehicleOffenceBySearch = () => ( dispatch : Function ) => {
	dispatch( { type : SET_VEHICLE_OFFENCE_BY_SEARCH, payload: [] } )
};

export const clearVehicleOffenceById = () => ( dispatch : Function ) => {
	dispatch( { type : SET_VEHICLE_OFFENCE_BY_ID, payload: {} } )
};

export const addNewVehicleOffence = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/vehicleoffense`, actionEnum: ActionEnums.ADD_VEHICLE_OFFENCE, data
	});

export const getAllVehicleOffencePayment = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/vehicle`,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENT,
		disPatch: [{type: SET_VEHICLE_OFFENSE_PAYMENT, payload: 'data' }], pageNumber, pageSize,
	});

export const getVehicleOffencePaymentBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/vehicle`,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH, payload: 'data' }]
	});

export const getVehicleOffencePaymentById = (id:number) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/vehicle`,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENT_BY_ID, id,
		disPatch: [{type: SET_VEHICLE_OFFENCE_PAYMENT_BY_ID, payload: '' }]
	});

export const clearVehicleOffencePaymentBySearch = ( ) => ( dispatch: Function ) => {
	dispatch({type: SET_VEHICLE_OFFENCE_PAYMENT_BY_SEARCH, payload: [] })
};

export const clearVehicleOffencePaymentById = ( ) => ( dispatch: Function ) => {
	dispatch({type: SET_VEHICLE_OFFENCE_PAYMENT_BY_ID, payload: {} })
};

export const getOutstandingVehicleOffencePayments = (pageNumber?: number, pageSize?: number) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleOffense/offender/outstanding/contact`,
		actionEnum: ActionEnums.GET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS,
		disPatch: [{type: SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS, payload: 'data' }], pageNumber, pageSize,
	});

export const getOutstandingVehicleOffencePaymentsBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/vehicleOffense/offender/outstanding/contact`,
		actionEnum: ActionEnums.GET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH, payload: 'data' }]
	});

export const clearOutstandingVehicleOffencePaymentsBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_ALL_OUTSTANDING_VEHICLE_OFFENCE_PAYMENTS_BY_SEARCH, payload: [] })
};

export const getAllVehicleOffencePaymentStatus = () => 
	getRequest({
		url: `${etraffica_baseurl}/paymentstatus`, actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENT_STATUSES,
		disPatch: [{type: SET_VEHICLE_OFFENSE_PAYMENT_STATUSES, payload: 'data' }]
	});