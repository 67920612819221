import { ActionEnums } from '../../../enums/ActionEnums'
import { SET_ALL_YARDS, SET_ALL_YARD_LOTS,SET_IMPOUND_REQUESTS, SET_IMPOUND_REQUEST_BY_ID, SET_IMPOUND_STATUS, SET_IMPOUND_STATUS_BY_ID, SET_ALL_IMPOUNDS, SET_IMPOUNDS_BY_SEARCH, SET_ALL_IMPOUND_RR, SET_IMPOUND_RR_BY_SEARCH, SET_YARD_LOT_BY_ID, SET_YARD_LOT_BY_YARD_ID, } from '../types';
import { etraffica_baseurl } from '../../../utils/constants';
import { getRequest, postRequest } from 'store/services';

export const getYards = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/yard`,
		actionEnum: ActionEnums.GET_ALL_YARDS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_YARDS, payload: 'data' }]
	});

export const createYard = ( data : object, update?: boolean ) => 
	postRequest({
		url:  update ? `${etraffica_baseurl}/yard/update` : `${etraffica_baseurl}/yard`,
		actionEnum: update ? ActionEnums.UPDATE_YARD : ActionEnums.ADD_YARD, data, update
	});

export const getYardLots = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/yardlots`,
		actionEnum: ActionEnums.GET_ALL_YARD_LOTS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_YARD_LOTS, payload: 'data' }]
	});

export const getYardLotById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/yardlots`,
		actionEnum: ActionEnums.GET_YARD_LOT_BY_ID, id,
		disPatch: [{type: SET_YARD_LOT_BY_ID, payload: 'data' }]
	});

export const getYardLotByYardId = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/yardlots/yard`,
		actionEnum: ActionEnums.GET_YARD_LOT_BY_YARD_ID, id,
		disPatch: [{type: SET_YARD_LOT_BY_YARD_ID, payload: 'data' }]
	});

export const clearYardLotById = () => (dispatch: Function) => {
	dispatch({ type: SET_YARD_LOT_BY_ID, payload: {} });
};

export const clearYardLotByYardId = () => (dispatch: Function) => {
	dispatch({ type: SET_YARD_LOT_BY_YARD_ID, payload: [] });
};

export const createYardLot = ( data : object, update?: boolean ) => 
	postRequest({
		url:  update ? `${etraffica_baseurl}/yardlots/update` : `${etraffica_baseurl}/yardlots`,
		actionEnum: update ? ActionEnums.UPDATE_YARD_LOT : ActionEnums.ADD_YARD_LOT , data, update
	});

export const getImpounds = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impound`,
		actionEnum: ActionEnums.GET_ALL_IMPOUNDS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_IMPOUNDS, payload: 'data' }]
	});

export const getImpoundsBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impound`,
		actionEnum: ActionEnums.GET_IMPOUNDS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_IMPOUNDS_BY_SEARCH, payload: 'data' }]
	});

export const getImpoundRequest = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impoundrequest`,
		actionEnum: ActionEnums.GET_IMPOUND_REQUEST, pageNumber, pageSize,
		disPatch: [{type: SET_IMPOUND_REQUESTS, payload: 'data' }]
	});

export const getImpoundRequestById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impoundrequest`,
		actionEnum: ActionEnums.GET_IMPOUND_REQUEST_BY_ID, id,
		disPatch: [{type: SET_IMPOUND_REQUEST_BY_ID, payload: 'data' }]
	});

export const createImpound = ( action : string, data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/impound/update` : `${etraffica_baseurl}/impound`,
		actionEnum: action, data, update
	});

export const approveImpoundReleaseRequest = ( action : string, data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/impoundreleaserequest/approve`, actionEnum: action, data
	});

export const createImpountInterest = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/impoundrequest/update` : `${etraffica_baseurl}/impoundrequest`,
		actionEnum: update ? ActionEnums.UPDATE_IMPOUND_REQUEST : ActionEnums.ADD_IMPOUND_REQUEST, data, update
	});

export const getImpoundStatus = () => 
	getRequest({
		url: `${etraffica_baseurl}/impoundstatus`, actionEnum: ActionEnums.GET_ALL_IMPOUND_STATUS,
		disPatch: [{type: SET_IMPOUND_STATUS, payload: 'data' }]
	});

export const getImpoundStatusById = (id : number) => 
	getRequest({
		url: `${etraffica_baseurl}/impoundstatus`,
		actionEnum: ActionEnums.GET_IMPOUND_STATUS_BY_ID, id,
		disPatch: [{type: SET_IMPOUND_STATUS_BY_ID, payload: 'data' }]
	});

export const createImpoundStatus = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/impoundstatus/update` : `${etraffica_baseurl}/impoundstatus`,
		actionEnum: update ? ActionEnums.UPDATE_IMPOUND_STATUS : ActionEnums.ADD_IMPOUND_STATUS , data, update
	});

export const createImpoundReleaseRequest = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/impoundreleaserequest/update` : `${etraffica_baseurl}/impoundreleaserequest`,
		actionEnum: update ? ActionEnums.UPDATE_IMPOUND_RELEASE_REQUEST : ActionEnums.CREATE_IMPOUND_RELEASE_REQUEST,
		data, update
	});

export const getImpoundRR = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impoundreleaserequest`,
		actionEnum: ActionEnums.GET_ALL_IMPOUND_RELEASE_REQUESTS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_IMPOUND_RR, payload: 'data' }]
	});

export const getImpoundRRBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/impoundreleaserequest`,
		actionEnum: ActionEnums.GET_IMPOUND_RELEASE_REQUESTS_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_IMPOUND_RR_BY_SEARCH, payload: 'data' }]
	});