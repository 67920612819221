import { ActionEnums } from '../../../enums/ActionEnums'
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_OFFENCES_BY_SEARCH, SET_OFFENCE_CATEGORIES, SET_OFFENCE_LIST } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getOffenceList = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offense`,
		actionEnum: ActionEnums.GET_OFFENCE_LIST, pageNumber, pageSize,
		disPatch: [{type: SET_OFFENCE_LIST, payload: 'offenses' }]
	});

export const getOffencesBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/offense`,
		actionEnum: ActionEnums.GET_OFFENCES_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_OFFENCES_BY_SEARCH, payload: 'offenses' }]
	});

export const clearOffencesBySearch = ( ) => ( dispatch : Function ) =>{
	dispatch(dispatch( { type : SET_OFFENCES_BY_SEARCH, payload: [] } ))
};

export const createOffence = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/offense/update` : `${etraffica_baseurl}/offense`,
		actionEnum: update ? ActionEnums.UPDATE_OFFENCE : ActionEnums.CREATE_OFFENCE, data, update
	});

export const getOffenceCategories = () => 
	getRequest({
		url: `${etraffica_baseurl}/offense`,
		actionEnum: ActionEnums.GET_OFFENCE_CATEGORY,
		disPatch: [{type: SET_OFFENCE_CATEGORIES, payload: '' }]
	});

export const createOffenceCategory = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/OffenseCategory/update` : `${etraffica_baseurl}/OffenseCategory`,
		actionEnum: update ? ActionEnums.UPDATE_OFFENCE_CATEGORY : ActionEnums.CREATE_OFFENCE_CATEGORY, data, update
	});