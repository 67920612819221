import { ActionEnums } from '../../../enums/ActionEnums'
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_BANKS, SET_ALL_BANK_CASH_DEPOSITS, SET_ALL_BANK_DEPOSIT_BATCHES, SET_ALL_CASH_PAYMENTS, SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES, SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH, SET_BANKS_BY_SEARCH, SET_BANK_CASH_DEPOSITS_BY_SEARCH, SET_BANK_DEPOSIT_BATCHES_BY_SEARCH, SET_CASH_PAYMENTS_BY_SEARCH, SET_INVOICE_RECONCILIATIONS, SET_INVOICE_RECONCILIATIONS_BY_SEARCH, SET_REVENUE_GROUPS } from '../types';
import { getRequest, postRequest } from 'store/services';

export const generateInvoiceId = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/OffensePayment/vehicle/cbs/invoice`,
		actionEnum: ActionEnums.GET_INVOICE_ID, data
	});

export const revertCashPayment = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/cashpayment/post/invoice/reversal`,
		actionEnum: ActionEnums.REVERSE_CASH_PAYMENT, data
	});

export const getCashPayments = (pageNumber?: number, pageSize?: number, statusId?: string) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/vehicle`,
		actionEnum: ActionEnums.GET_ALL_CASH_PAYMENTS, extraUrl: `&paymentStatusId=${statusId}`,
		disPatch: [{type: SET_ALL_CASH_PAYMENTS, payload: 'data' }], pageNumber, pageSize,
	});

export const getCashPaymentsBySearch = (query?: string, pageNumber?: number, pageSize?: number, statusId?: string) => 
	getRequest({
		url: `${etraffica_baseurl}/offensepayment/vehicle`,
		actionEnum: ActionEnums.GET_CASH_PAYMENTS_BY_SEARCH, extraUrl: `&paymentStatusId=${statusId}`,
		disPatch: [{type: SET_CASH_PAYMENTS_BY_SEARCH, payload: 'data' }], pageNumber, pageSize, query
	});

export const clearCashPaymentBySearch = () => (dispatch:Function) => {
	dispatch({ type: SET_CASH_PAYMENTS_BY_SEARCH, payload: [] })
};

export const syncCashPayment = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/OffensePayment/synchronize`,
		actionEnum: ActionEnums.SYNC_CASH_PAYMENT, data
	});

export const getRevenueGroups = () => 
	getRequest({
		url: `${etraffica_baseurl}/revenuegroup`,
		actionEnum: ActionEnums.GET_ALL_REVENUE_GROUPS,
		disPatch: [{type: SET_REVENUE_GROUPS, payload: '' }]
	});

//pageCount?
export const getIReconciliations = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `https://invoices.myapiservices.net/api/v1/Invoices/GetAllWithPagination?pageSize=${ pageSize }&pageNumber=${pageNumber}`,
		actionEnum: ActionEnums.GET_INVOICE_RECONCILIATIONS,
		disPatch: [{type: SET_INVOICE_RECONCILIATIONS, payload: 'invoices' }]
	});

export const getIReconciliationsBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `https://invoices.myapiservices.net/api/v1/Invoices/GetAllWithPagination?${ query }pageNumber=${pageNumber}&pageSize=${pageSize}`,
		actionEnum: ActionEnums.GET_INVOICE_RECONCILIATIONS_BY_SEARCH,
		disPatch: [{type: SET_INVOICE_RECONCILIATIONS_BY_SEARCH, payload: 'invoices' }]
	});

export const clearIReconciliationsBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_INVOICE_RECONCILIATIONS_BY_SEARCH, payload: [] })
};

// Finance Bank Manager
export const getAllBanks = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bank`,
		actionEnum: ActionEnums.GET_ALL_BANKS, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_BANKS, payload: 'data' }]
	});

export const getBanksBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bank`,
		actionEnum: ActionEnums.GET_BANKS_BY_SEARCH, pageNumber, pageSize,
		disPatch: [{type: SET_BANKS_BY_SEARCH, payload: 'data' }]
	});

export const createBank = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/bank/update` : `${etraffica_baseurl}/bank`,
		actionEnum: update? ActionEnums.UPDATE_BANK : ActionEnums.CREATE_BANK, data, update
	});

export const clearBanksBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_BANKS_BY_SEARCH, payload: [] })
};

export const getBankDepositBatches = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bankdepositbatch`,
		actionEnum: ActionEnums.GET_ALL_BANK_DEPOSIT_BATCHES, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_BANK_DEPOSIT_BATCHES, payload: 'data' }]
	});

export const getBankDepositBatchesBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bankdepositbatch`,
		actionEnum: ActionEnums.GET_BANK_DEPOSIT_BATCHES_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_BANK_DEPOSIT_BATCHES_BY_SEARCH, payload: 'data' }]
	});

export const clearBankDepositBatchesBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_BANK_DEPOSIT_BATCHES_BY_SEARCH, payload: [] })
};

export const getBankCashDeposits = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bankcashdeposit`,
		actionEnum: ActionEnums.GET_ALL_BANK_CASH_DEPOSIT, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_BANK_CASH_DEPOSITS, payload: 'data' }]
	});

export const getBankCashDepositsBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/bankdepositbatch`,
		actionEnum: ActionEnums.GET_BANK_CASH_DEPOSIT_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_BANK_CASH_DEPOSITS_BY_SEARCH, payload: 'data' }]
	});

export const clearBankCashDepositsBySearch = () => (dispatch: Function) => {
	dispatch({ type: SET_BANK_CASH_DEPOSITS_BY_SEARCH, payload: [] }) 
};

export const createBankCashDeposits = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/bankcashdeposit/update` : `${etraffica_baseurl}/bankcashdeposit`,
		actionEnum: update ? ActionEnums.UPDATE_BANK_CASH_DEPOSIT : ActionEnums.CREATE_BANK_CASH_DEPOSIT, data, update
	});

export const createBankDepositBatch = ( data : object, update?: boolean ) => 
	postRequest({
		url: update ? `${etraffica_baseurl}/bank/bankdepositbatch` : `${etraffica_baseurl}/bankdepositbatch`,
		actionEnum: update ? ActionEnums.UPDATE_BANK_DEPOSIT_BATCH : ActionEnums.CREATE_BANK_DEPOSIT_BATCH, data, update
	});

export const getVehicleOffencePaymentsWithoutInvoices = (pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/OffensePayment/vehicle/without/invoice`, noPaged: true,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES, pageNumber, pageSize,
		disPatch: [{type: SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES, payload: 'data' }]
	});

export const getVehicleOffencePaymentsWithoutInvoicesBySearch = (query?: string, pageNumber?: number, pageSize? : number ) => 
	getRequest({
		url: `${etraffica_baseurl}/OffensePayment/vehicle/without/invoice`, noPaged: true,
		actionEnum: ActionEnums.GET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH, pageNumber, pageSize, query,
		disPatch: [{type: SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH, payload: 'data' }]
	});

export const clearVehicleOffencePaymentsWithoutInvoicesBySearch = () => (dispatch: Function) => {
	dispatch({
		type: SET_ALL_VEHICLE_OFFENCE_PAYMENTS_W0_INVOICES_BY_SEARCH, payload: []
   })
};

export const reconcileInvoice = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/reconciliationmanager/cbs/process/invoice`,
		actionEnum: ActionEnums.RECONCILE_INVOICE, data,
	});

export const fixDuplicateInvoice = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/reconciliationmanager/cbs/duplicate/invoice/fix`,
		actionEnum: ActionEnums.FIX_DUPLICATE_INVOICE, data,
	});