import {
	SET_ADMIN_AUTHENTICATED, SET_ADMIN_UNAUTHENTICATED, AXIOS_REQUEST,
	AXIOS_REQUEST_FAILURE, AXIOS_REQUEST_SUCCESS, SET_VEHICLE_OFFENSE, SET_PAID_OFFENCES,
	SET_UNPAID_OFFENCES, SET_TOTAL_PAID_OFFENCES, SET_TOTAL_UNPAID_OFFENCES
} from "../actions/types";

const initialState = {
	action: '',
	loading: false,
	errors: '',
	data: [],
	paidOffences: [],
	unpaidOffences: [],
	totalPaid: 0,
	totalUnpaid: 0,
	vehicleOffenses: []
};

const violatorReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		case SET_ADMIN_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
			};
		case SET_ADMIN_UNAUTHENTICATED:
			return {
				...state,
				authenticated: false
			};
		case SET_VEHICLE_OFFENSE:
			return {
				...state,
				vehicleOffenses: action.payload
			};
		case SET_PAID_OFFENCES:
			return {
				...state,
				paidOffenses: action.payload
			};
		case SET_UNPAID_OFFENCES:
			return {
				...state,
				unpaidOffenses: action.payload
			};
		case SET_TOTAL_PAID_OFFENCES:
			return {
				...state,
				totalPaid: action.payload
			};
		case SET_TOTAL_UNPAID_OFFENCES:
			return {
				...state,
				totalUnpaid: action.payload
			};
		default:
			return state;
	};
};

export default violatorReducer;