import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_TEAMS, SET_TEAM_DEVICES } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllTeams = () => 
	getRequest({
		url: `${etraffica_baseurl}/team`, actionEnum: ActionEnums.GET_ALL_TEAMS,
		disPatch:[{type: SET_ALL_TEAMS, payload: 'data'}]
	});

export const addNewTeam = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/team/update` : `${etraffica_baseurl}/team`,
		actionEnum: update? ActionEnums.UPDATE_TEAM : ActionEnums.ADD_NEW_TEAM, data, update
	});

export const assignDeviceToTeam = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/teamdevise`, actionEnum: ActionEnums.ASSIGN_DEVICE_TEAM, data
	});

export const getTeamDevices = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/teamdevise/name`, actionEnum: ActionEnums.GET_TEAM_DEVICES, data,
		disPatch:[{type: SET_TEAM_DEVICES, payload: 'data'}]
	});