import React, { FC, useState, useEffect } from 'react';
import { logOutAdmin, getRoles } from "../../store/actions";
import { IAdmin } from "../../interfaces/IAdmin";
import { connect } from "react-redux";
import { IAppState } from '../../interfaces/IAppState';
import NewSideMenu from './sideMenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { decodedToken } from '../../utils/token';
import { useNavigate } from 'react-router-dom';
import { url } from '../../enums/Route';
import { IAdminRole } from '../../interfaces/IAdminRole';
import './index.scss';

interface IStateProps {
    admin: IAdmin;
    adminRole:  IAdminRole;
}

interface IDispatchProps {
    logOutAdmin: () => void;
    getRoles: () => void;
}

interface IProps extends IStateProps, IDispatchProps { }

const Navbar: FC<IProps> = (props) => {
    const { logOutAdmin, getRoles, adminRole } = props;
    const { roles = [] } = adminRole;
    const [ sidebar, setSidebar] = useState(false);
    const navigate = useNavigate();
    var roleName = roles!?.filter(r => r!?.id === parseInt(decodedToken!?.role) )[0]!?.name;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const toggleSidebar = () => {
        setSidebar(!sidebar);
    };

    useEffect(() => {
        roles!?.length === 0 && getRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        {sidebar && <div className="backdrop" onClick={() => setSidebar(false)}></div>}
        <div className='navigations'>
            <header className="navbar">
                <div className='navs'>
                    <i className={sidebar ? 'fa fa-times': 'fa fa-bars'} aria-hidden='true' onClick={toggleSidebar}></i>
                </div>
                <Typography variant="h5" className="title" >
                    {'e-Traffika'}
                </Typography>
                <div className='icons'>
                    <p>Role:<b> {roleName}</b></p>
                    <p style={{ fontWeight: 700, textTransform: 'uppercase', letterSpacing: '2px', color: 'rgb(79, 79, 79)', fontSize:'14px' }}>
                        { decodedToken!?.unique_name }
                    </p>
                    {/* <Avatar variant='circular' ></Avatar> */}
                    <i className='fa fa-ellipsis-v' onClick={handlePopoverClick}></i>
                    <Menu  id="fade-menu"  anchorEl={anchorEl} keepMounted open={open} onClose={handlePopoverClose} >
                        {/* <MenuItem onClick={handlePopoverClose}>Download Overview</MenuItem>
                        <MenuItem onClick={handlePopoverClose}>Edit Profile</MenuItem> */}
                        <MenuItem onClick={() => { navigate(url.ADMINPROFILE); handlePopoverClose()}}>View Profile</MenuItem>
                        <MenuItem onClick={() => {logOutAdmin()}}>Logout</MenuItem>
                    </Menu>
                </div>
            </header>
        </div>
        <NewSideMenu isMenuOpen={ sidebar } toggleSidebar={ toggleSidebar }/> 
        </>
    )
};

const mapStateToProps = (state: IAppState): IStateProps => ({
	admin: state.admin,
    adminRole: state.adminRole
});

const mapDispatchToProps: IDispatchProps = {
    logOutAdmin,
    getRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);