import {
	AXIOS_REQUEST,
	AXIOS_REQUEST_FAILURE,
	AXIOS_REQUEST_SUCCESS,
	SET_SERVICE_GROUPS,
	SET_VENDORS,
	SET_VENDOR_SERVICES,
	SET_VENDOR_SERVICES_BY_ID,
	SET_ESERVICE_GROUPTAX_BY_ID,
	SET_ESERVICE_ACCOUNTS,
	SET_ESERVICE_ACCOUNT_TYPES,
	SET_TRANSACTIONS,
	SET_TRANSACTIONS_BY_SEARCH_PARAMS,
	CLEAR_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS,
	CLEAR_TRANSACTION_BY_SEARCH_PARAMS,
	SET_TRANSACTION_REQUESTS,
	SET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS,
	SET_TOTAL_COUNT, SET_TOTAL_SEARCH_COUNT
} from "../actions/types";

const initialState = {
	loading: false,
	action: '',
	errors: '',
	data: [],
	serviceGroups: [],
	vendors: [],
	vendorServices: [],
	vendorServicesById: [],
	serviceGroupTaxById: {},
	accounts: [],
	accountTypes: [],
	transactions: [],
	trasactionsBySearchParams: [],
	transactionRequests: [],
	transactionRequestsBySearchParams: [],
	totalCount: 0,
	totalSearchCount: 0
};

const eCommerceReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		case SET_SERVICE_GROUPS:
			return {
				...state,
				serviceGroups: action.payload
			};
		case SET_VENDORS:
			return {
				...state,
				vendors: action.payload
			};
		case SET_VENDOR_SERVICES:
			return {
				...state,
				vendorServices: action.payload
			};
		case SET_VENDOR_SERVICES_BY_ID:
			return {
				...state,
				vendorServicesById: action.payload
			};
		case SET_ESERVICE_GROUPTAX_BY_ID:
			return {
				...state,
				serviceGroupTaxById: action.payload
			};
		case SET_ESERVICE_ACCOUNTS:
			return {
				...state,
				accounts: action.payload
			};
		case SET_ESERVICE_ACCOUNT_TYPES:
			return {
				...state,
				accountTypes: action.payload
			};
		case SET_TRANSACTIONS:
			return {
				...state,
				transactions: action.payload
			};
		case SET_TRANSACTIONS_BY_SEARCH_PARAMS:
			return {
				...state,
				trasactionsBySearchParams: action.payload
			};
		case CLEAR_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS:
			return {
				...state,
				transactionRequestsBySearchParams: []
			};
		case CLEAR_TRANSACTION_BY_SEARCH_PARAMS:
			return {
				...state,
				trasactionsBySearchParams: []
			};
		case SET_TRANSACTION_REQUESTS:
			return {
				...state,
				transactionRequests: action.payload
			};
		case SET_TRANSACTION_REQUESTS_BY_SEARCH_PARAMS:
			return {
				...state,
				transactionRequestsBySearchParams: action.payload
			};
		case SET_TOTAL_COUNT:
			return {
				...state,
				totalCount : action.payload
			};
		case SET_TOTAL_SEARCH_COUNT:
			return {
				...state,
				totalSearchCount : action.payload
			};
		default:
			return state;
	};
};

export default eCommerceReducer;