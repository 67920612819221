import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_ALL_USERS } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getAllUsers = () => 
	getRequest({
		url: `${etraffica_baseurl}/user`, actionEnum: ActionEnums.GET_ALL_USERS,
		disPatch:[{type: SET_ALL_USERS, payload: ''}]
	});

export const addNewUser = ( data : object, update?: boolean ) => 
	postRequest({
		url: update? `${etraffica_baseurl}/auth/user/update` : `${etraffica_baseurl}/auth/register`,
		actionEnum: update? ActionEnums.UPDATE_USER : ActionEnums.ADD_NEW_USER, data, update
	});

export const changePassword = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/auth/user/update/password`, actionEnum: ActionEnums.CHANGE_PASSWORD, data
	});

export const disableUser = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/disable-user`, actionEnum: ActionEnums.DISABLE_USER, data
	});

export const updateUserRole = ( data : object ) => 
	postRequest({
		url: `${etraffica_baseurl}/user/update/role`, actionEnum: ActionEnums.UPDATE_USER_ROLE, data
	});