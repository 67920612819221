import { ActionEnums } from '../../../enums/ActionEnums';
import { etraffica_baseurl } from "../../../utils/constants";
import { SET_CITIES, SET_STATES } from '../types';
import { getRequest, postRequest } from 'store/services';

export const getStates = () => 
	getRequest({
		url: `${etraffica_baseurl}/state`, actionEnum: ActionEnums.GET_STATES,
		disPatch: [{type: SET_STATES, payload: 'states' }]
	});

export const getCities = () => 
	getRequest({
		url: `${etraffica_baseurl}/city`, actionEnum: ActionEnums.GET_CITIES,
		disPatch: [{type: SET_CITIES, payload: 'city' }]
	});

export const createCountry = ( data : object) => 
	postRequest({
		url: `${etraffica_baseurl}/country`, actionEnum: ActionEnums.CREATE_COUNTRY , data
	});

export const createState = ( data : object, update?: boolean ) => 
	postRequest({
		url: `${etraffica_baseurl}/state${update && `/update`}`,
		actionEnum: update ? ActionEnums.UPDATE_STATE : ActionEnums.CREATE_STATE , data, update
	});

export const createCity = ( data : object, update?: boolean ) => 
	postRequest({
		url: `${etraffica_baseurl}/city${update && `/update`}`,
		actionEnum: update ? ActionEnums.UPDATE_CITY : ActionEnums.CREATE_CITY , data, update
	});