import { etraffica_baseurl } from "../../utils/constants";
import { ActionEnums } from '../../enums/ActionEnums';
import { getRequest } from "store/services";
import { SET_ALL_INSTANCES, SET_INSTANCE_BY_SEARCH, CLEAR_INSTANCE_BY_SEARCH, SET_INSTANCE_THUMBNAILS,
	SET_INSTANCE_BY_ID } from "./types";

const checkType = (type: string) => {
	switch (type) {
		case 'taxi':  return `${etraffica_baseurl}/instancelogger/taxi`
		default: return `${etraffica_baseurl}/instancelogger`
	}
};

export const getInstances = (pageNumber?: number, pageSize?: number, type?: string) => 
	getRequest({
		url: checkType(type!), actionEnum: ActionEnums.GET_ALL_INSTANCES, isInstance: true,
		disPatch: [{type: SET_ALL_INSTANCES, payload: 'data' }], pageNumber, pageSize,
	});

export const getInstancesBySearch = (query?: string, pageNumber?: number, pageSize? : number, isThumbnails? : boolean, type?: string ) => 
	getRequest({
		url: checkType(type!), actionEnum: ActionEnums.GET_VEHICLE_BY_SEARCH,
		pageNumber, pageSize, query, isInstance: !isThumbnails, 
		disPatch: [{type: isThumbnails ? SET_INSTANCE_THUMBNAILS : SET_INSTANCE_BY_SEARCH, payload: 'data' }]
	});

export const getInstanceById = (id:number ) => 
	getRequest({
		url: `${etraffica_baseurl}/instancelogger`, actionEnum: ActionEnums.GET_INSTANCE_BY_ID, id,
		disPatch: [{type: SET_INSTANCE_BY_ID, payload: 'data' }], isInstance: true
	});

export const clearInstanceById = ( ) => ( dispatch : Function ) => {
	dispatch( { type : SET_INSTANCE_BY_ID, payload: {} } )
};

export const clearInstanceBySearch = ( ) => ( dispatch : Function ) => {
	dispatch( { type : CLEAR_INSTANCE_BY_SEARCH } )
}